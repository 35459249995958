<div class="container-fluid pt-5">
  <div class="row main-div">

    <div class="col-lg-4 col-md-4 col-sm-12 mb-5">
      <h3>Edit <span class="info"> Profile</span></h3>
      <hr class="horizontal-bar2" />
      <div class="text-center display_inline">
        <img class="card-img my-4 " src="../../assets/user.png"
        alt="Card image" style="width:40%;">
      </div>
      <div class="change-profile">
        <a>Change your Profile Picture</a>
      </div>
      <div class="col-lg-4 col-md-4 mt-3 text-center">
        <a mat-raised-button color="warn"
        *ngIf="role !== 'MEMBER'&&this.isChangePasswordShown"
        routerLink="/admin/change-password">
        Change Password</a>
        <a mat-raised-button color="warn" *ngIf="role === 'MEMBER'"
        routerLink="/member/change-password">
        Change Password</a>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <form [formGroup]="formVariable" (ngSubmit)="onSubmit()">


        <mat-form-field appearance="fill" class="w-100 mb-2">
          <mat-label>Change Nationality</mat-label>
          <mat-select [disabled]="disableSelect.value"
          formControlName="nationality">
            <mat-option value="Indian">Indian</mat-option>
            <mat-option value="Singaporean">Singaporean</mat-option>
            <mat-option value="American">American</mat-option>
            <mat-option value="British">British</mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field class="w-100 mb-2">
        <mat-label>Change Address</mat-label>
        <textarea matInput placeholder="Enter your new address"
        formControlName="sgAddress"></textarea>
      </mat-form-field>

      <mat-form-field class="w-100 mb-2">
        <mat-label>Change Phonenumber</mat-label>
        <span matPrefix>+65 &nbsp;</span>
        <input type="tel" formControlName="mobileNo"
        matInput placeholder="8 digits of SG Mobile Number">
      </mat-form-field>

      <mat-form-field class="w-100 mb-2">
        <mat-label>Change Emergency Contact</mat-label>
        <span matPrefix>+91 &nbsp;</span>
        <input type="tel" formControlName="emergencyContactInIndia"
        matInput placeholder="10 Digits of Indian Mobile Number">
      </mat-form-field>

      <mat-form-field class="w-100 mb-2">
        <mat-label>Profession</mat-label>
        <input matInput #message formControlName="profession"
        placeholder="Enter your new profession">
      </mat-form-field>

      <div class="text-center">
        <button mat-raised-button
          class="btn btn-sm save mr-2" type="submit"
          (click)="openSnackBar()">Save</button>
        <button mat-raised-button
          class="btn btn-sm cancel ml-2"  type="button"
         *ngIf="role === 'MEMBER'"
        routerLink="/member/member-profile"
        >Back</button>
        <button class="btn btn-sm cancel ml-2"
        mat-raised-button
        *ngIf="role === 'TREASURER' || role === 'PRESIDENT' ||  role === 'SECRETARY'"
        type="button" routerLink="/admin/profile">Back</button>
      </div>
      </form>
    </div>

  </div>

</div>

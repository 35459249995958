import { Component } from '@angular/core';
import { Login } from '../models/login';
import { LoginService } from '../services/login-service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private route: ActivatedRoute,
    private router: Router,
    
    private loginService: LoginService) { }
  ngOnInit(): void {
  }
  loginSuccessFul: boolean;
  showErrorMessage: string;
  login = new Login();
  returnUrl: string;
  loading: boolean;
  loginMessage: string;
  hide = true;

  public singInWithEmailAndPwd(): void {

    if (this.login.emailId == null  || this.login.password ==null) {
      this.loginMessage = 'Enter your Email & Password to Login.'
      // this.loading = true;

    } else {
      this.loginMessage = null;
      this.loading = true;
      this.loginSuccessFul = false;
      this.showErrorMessage = null;
      this.loginService.loginAuth(this.login).subscribe(result => {
        this.login = result;
        localStorage.setItem('memberid', this.login.userId);
        localStorage.setItem('email', this.login.userId);
        localStorage.setItem('name', this.login.fullName);
        localStorage.setItem('role', this.login.role);
        localStorage.setItem('createdon', this.login.createdOn);
        localStorage.setItem('jwtToken', this.login.jwtToken);
        if (result.loginSuccess) {
          this.loading = false;
          if (result.role == 'MEMBER') {
            this.router.navigate(['/member/dashboard']);
          }
          else
            this.router.navigate(['/admin'])
            
        }
      }, 
      error => {
        console.log(error.error.erroMessage);
        this.loading = false;
        this.showErrorMessage = error.error.erroMessage;
      });
    }
    


  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login-service';
import { Observable } from 'rxjs';
import { Login } from '../models/login';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
  }

  login = new Login();
  showMessage: string;

  public invokeForgotPwdService(): void {

    this.loginService.getPwd(this.login).subscribe(result => {
      console.log(result);
      this.showMessage = "Email has been sent if user is exist..Please check";
      if (result.isEmailSent) {
        console.log(".INNNN");
        //this.showMessage = "Email has been sent if user is exist..Please check";
      }

    });
  }

}

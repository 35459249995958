export class ApproveInfo {

  approvalId: number;
  action: string; 
  reason: string;
  membershipId: string;
  approver: string;
  userApprovalLevel: string; 
  email: string;
  transactionRefId: string;
}

import { LoginService } from "./../services/login-service";
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { AdminService } from "../services/admin.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  userName: string;
  profession: string;
  paymentHistoryDetails: any;
  personalDetails: any;
  spouseDetails: any;
  childDetails: any;
  toHideDivs: any;
  role: string;
  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  memberShipId: string;
  isUpgradeBtnShown: boolean;

  ngOnInit(): void {
    this.memberShipId = this.route.snapshot.paramMap.get("memberShipId");
    this.role = localStorage.getItem('role');
    
    //scroling to paymenthistory div in profile component
    const url = this.router.url.split('#')
    if (url.length === 2){
      const paymentLocation = document.getElementById('paymentHistory').offsetTop;
      // console.log(paymentLocation);
      this.document.body.scrollTop = paymentLocation;
    }



    if (this.memberShipId == null) {
      this.memberShipId = localStorage.getItem("memberid");
    }

    this.adminService
      .getMemberProfile(this.memberShipId)
      .subscribe((result) => {
        this.personalDetails = result;
        this.toHideDivs = this.personalDetails?.membershipIDType;
        if (this.memberShipId == localStorage.getItem("memberid")) {
          this.isUpgradeBtnShown = true;
        }
        console.log(this.personalDetails);
        console.log(this.toHideDivs);
      });
  }

  ngOnDestroy(): void {}
}

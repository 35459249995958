<div class="row">
  <div class="col-md-3 col-lg-3 col-sm-12 pr-0">
    <div class="card">
      <div class="text-center d-inline">
        <img
          class="card-img mt-3"
          src="../../assets/user.png"
          alt="Card image"
          style="width: 40%"
        />
      </div>
      <div class="card-body text-center px-0">
        <h4 class="card-title">{{ userName }}</h4>
        <p>{{ role }}</p>
        <h5 class="py-1 text-success">{{ personalDetails?.memberStatus }}</h5>
        <p style="margin-top: 1rem">Update profile Photo</p>
        <hr />
        <div class="text-left card-details px-5">
          <i class="fa fa-star" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            Member Since <br />
            {{ createdOn | date: "dd-MMM-yyyy" }}
          </p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-arrow-up" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a
              *ngIf="personalDetails?.membershipIDType !== 'familyAnnual'"
              routerLink="/member/renew-payment"
              matTooltip="Upgrade your Plan"
              >Upgrade</a
            >
          </p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a routerLink="/member/dashboard" matTooltip="Your Payment History"
              >Payment History</a
            >
          </p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a
              routerLink="/member/messages"
              matTooltip="Check all Notifications"
              >Notifications</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 col-md-8 px-4">
    <mat-card class="card ml-2 w-100">
      <div class="card-body">
        <h4 class="mt-0 topRow">
          Membership Info of
          <span class="firstname">{{ personalDetails?.firstName }}</span>
        </h4>
        <hr class="horizontal-bar2" />
        <div class="row">
          <div class="col-lg-3 mt-3">
            <span>Membership Type</span>
            <h6>
              <i class="fa fa-users ml-0 mr-1" aria-hidden="true"></i>
              {{ personalDetails?.membershipIDType }}
            </h6>
          </div>
          <div class="col-lg-3 mt-3">
            <span>Membership ID</span>
            <h6>{{ personalDetails?.membershipID }}</h6>
          </div>

          <div class="col-lg-3 mt-3">
            <span>Membership Status</span>
            <h6>{{ personalDetails?.memberStatus }}</h6>
          </div>

          <div class="col-lg-3">
            <button
              *ngIf="personalDetails?.membershipIDType !== 'familyAnnual'"
              mat-raised-button
              class="btn btn-warning upgradeBtn mb-0"
              routerLink="/member/renew-payment"
              [matTooltipPosition]="'above'"
              matTooltip="Upgrade your Plan"
            >
              Upgrade
            </button>
            <button
              mat-raised-button
              class="btn btn-warning mt-2"
              style="background-color: #6d0005; color: white"
              routerLink="/member/edit-profile"
              matTooltip="Edit your Profile"
            >
              Edit Profile
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 mt-3">
            <span>Introduced Member1 ID</span>
            <h6>{{ personalDetails?.membershipOne }}</h6>
          </div>
          <div class="col-lg-3 mt-3">
            <span>Introduced Member2 ID</span>
            <h6>{{ personalDetails?.membershipTwo }}</h6>
          </div>
          <div class="col-lg-3 mt-3">
            <span>Membership Expiry Date</span>
            <h6>{{ personalDetails?.renewdOn | date: "dd-MMM-yyyy" }}</h6>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 mt-3">
            <span>Introduced Member1 Name</span>
            <h6>{{ personalDetails?.membershipOneName }}</h6>
          </div>
          <div class="col-lg-3 mt-3">
            <span>Introduced Member2 Name</span>
            <h6>{{ personalDetails?.membershipTwoName }}</h6>
          </div>
          <div class="col-lg-3 mt-3"></div>
        </div>
        <h4>Personal Details</h4>
        <hr class="horizontal-bar2" />
        <div class="row">
          <div class="col-lg-3 col-md-3 mt-2">
            <span>First Name</span>
            <h6>{{ personalDetails?.firstName }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Gender</span>
            <h6>{{ personalDetails?.gender }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Gotram</span>
            <h6>{{ personalDetails?.gotram }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Birthday</span>
            <h6>{{ personalDetails?.dateOfBirth | date: "dd-MMM-yyyy" }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3 mt-2">
            <span>NRIC/FIN</span>
            <h6>{{ personalDetails?.fin }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Preferred Name</span>
            <h6>{{ personalDetails?.preferredName }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>SG Address</span>
            <h6>{{ personalDetails?.sgAddress }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Mobile Number</span>
            <h6>{{ personalDetails?.mobileNo }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Email ID</span>
            <h6>{{ personalDetails?.email }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Nationality*</span>
            <h6>{{ personalDetails?.nationality }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Profession*</span>
            <h6>{{ personalDetails?.profession }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Gotram</span>
            <h6>{{ personalDetails?.gotram }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Industry </span>
            <h6>{{ personalDetails?.industry }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>India Address</span>
            <h6>{{ personalDetails?.indiaAddress }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Native Place/City</span>
            <h6>{{ personalDetails?.nativePlace }}</h6>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Blood Group</span>
            <h6>{{ personalDetails?.bloodGroup }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Emergency contact in India</span>
            <h6>{{ personalDetails?.emergencyContactInIndia }}</h6>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <span>Relative Staying In SG</span>
            <h6>{{ personalDetails?.relativeStayInSG }}</h6>
          </div>
        </div>
        <div
          class="spouse-div"
          *ngIf="
            personalDetails?.membershipIDType === 'familyLife' ||
            personalDetails?.membershipIDType === 'familyAnnual'
          "
        >
          <hr class="my-3" />
          <h4>Spouse {{ personalDetails?.membershipIDType }}</h4>
          <hr class="horizontal-bar2" />
          <div class="row">
            <div class="col-lg-3 mt-3">
              <span>First Name</span>
              <h6>{{ personalDetails?.firstNameSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Surname</span>
              <h6>{{ personalDetails?.surnameSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Preferred Name</span>
              <h6>{{ personalDetails?.preferredNameSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Birthday</span>
              <h6>
                {{ personalDetails?.dateOfBirthSpouse | date: "dd-MMM-yyyy" }}
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 mt-3">
              <span>NRIC/FIN</span>
              <h6>{{ personalDetails?.finSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Mobile Numnber</span>
              <h6>{{ personalDetails?.mobileNoSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Email ID</span>
              <h6>{{ personalDetails?.emailSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Nationality*</span>
              <h6>{{ personalDetails?.nationalitySpouse }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 mt-3">
              <span>Profession*</span>
              <h6>{{ personalDetails?.professionSpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Industry</span>
              <h6>{{ personalDetails?.industrySpouse }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>India Address</span>
              <h6>{{ personalDetails?.indiaAddress }}</h6>
            </div>
            <div class="col-lg-3 mt-3">
              <span>Native Place/City</span>
              <h6>{{ personalDetails?.nativePlaceSpouse }}</h6>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-lg-3 mt-3">
              <span>Blood Group*</span>
              <h6>{{ personalDetails?.bloodGroupSpouse }}</h6>
            </div>
          </div>
        </div>
        <div
          class="child-div"
          *ngIf="
            personalDetails?.membershipIDType === 'familyLife' ||
            personalDetails?.membershipIDType === 'familyAnnual'
          "
        >
          <hr class="my-3" />
          <h4 *ngIf="">Child</h4>
          <hr class="horizontal-bar2" />
          <div>
            <ng-container
              *ngFor="let childDetails of personalDetails?.userDependants"
            >
              <div class="row">
                <div class="col-lg-3 mt-3">
                  <span>First Name</span>
                  <h6>{{ childDetails?.firstname }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Surname</span>
                  <h6>{{ childDetails?.surname }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Gender</span>
                  <h6>{{ childDetails?.gender }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Birthday</span>
                  <h6>{{ childDetails?.dateOfBirth | date: "dd-MMM-yyyy" }}</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 mt-3">
                  <span>NRIC/FIN</span>
                  <h6>{{ childDetails?.fin }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Student Class</span>
                  <h6>{{ childDetails?.classstandard }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>School/College/University Name</span>
                  <h6>{{ childDetails?.university }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Blood Group*</span>
                  <h6>{{ childDetails?.bloodGroup }}</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 mt-3">
                  <span>NRIC/FIN</span>
                  <h6>{{ childDetails?.fin }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Student Class</span>
                  <h6>{{ childDetails?.classstandard }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>School/College/University Name</span>
                  <h6>{{ childDetails?.university }}</h6>
                </div>
                <div class="col-lg-3 mt-3">
                  <span>Blood Group*</span>
                  <h6>{{ childDetails?.bloodGroup }}</h6>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { LoginService } from './login-service';
import { Observable } from 'rxjs';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class KcasHttpInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const duplicate = req.clone({
      setHeaders: {
        Authorization: "Bearer " + this.loginService.getAutheToken()
      }
    });
    return next.handle(duplicate);
  }


}
<div class="container-fluid bg-light px-5 pb-5">
  <h3 class="pt-3 payment-styles" style="color: black">Payments</h3>
  <div class="cards mb-4 d-inline-flex w-100 flex-row align-items-center">
    <div class="col-md-3 mr-4 c2">
      <p>Pending Payments</p>
      <h3>${{ pendingFunds }}</h3>
    </div>

    <div class="col-md-3 mr-4 c1">
      <p>
        Monthly Received Funds <br />
        <span>({{ date | date: "MMM-yyyy" }})</span> <br />
        <span
          ><h3>${{ receivedFunds }}</h3></span
        >
      </p>
    </div>

    <div class="col-md-3 mr-4 c3">
      <p>Funds Recieved Today</p>
      <h3>${{ fundsReceivedForDay }}</h3>
    </div>
  </div>

  <h3 class="mt-1 d-flex flex-column align-items-end search-styles">
    <button
      class="mb-2 approve px-3"
      color="accent"
      *ngIf="role === 'TREASURER'"
      mat-raised-button
      [routerLink]="['/admin', 'approve-payment']"
    >
      Approve Payment
    </button>
    <div class="input-group col-md-4 pr-0">
      <input
        type="text"
        class="form-control ml-2"
        placeholder="Search"
        style="border-radius: 0"
        [(ngModel)]="searchInput"
        (keyup)="applyFilter()"
      />
      <button class="btn btn-success">
        <span>Search</span>
      </button>
    </div>
  </h3>

  <div class="mat-elevation-z8 mt-5 table-style">
    <table mat-table [dataSource]="dataSource">
      <!-- memberID Column -->
      <ng-container matColumnDef="membershipID">
        <th mat-header-cell *matHeaderCellDef>Member ID</th>
        <td mat-cell *matCellDef="let element">{{ element.membershipID }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="memberName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.memberName }}</td>
      </ng-container>

      <!-- role Column -->
      <ng-container matColumnDef="paymentStatus">
        <th mat-header-cell *matHeaderCellDef>Payment Status</th>
        <td mat-cell *matCellDef="let element" style="color: #2d9cdb">
          {{ element.paymentStatus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentsReceiveDate">
        <th mat-header-cell *matHeaderCellDef>Payment Received on</th>
        <td mat-cell *matCellDef="let element" style="color: #2d9cdb">
          {{ element.paymentsReceiveDate | date: "dd-MMM-yyyy" }}
        </td>
      </ng-container>

      <!-- paid Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount.SGD</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="text-align: right; padding-right: 3rem"
        >
          {{ element.amount }}
        </td>
      </ng-container>

      <!-- paidDate Column -->
      <ng-container matColumnDef="issueDate">
        <th mat-header-cell *matHeaderCellDef>Payment Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.issueDate | date: "dd-MMM-yyyy" }}
        </td>
      </ng-container>

      <!-- purpose Column -->
      <ng-container matColumnDef="purpose">
        <th mat-header-cell *matHeaderCellDef>Purpose</th>
        <td mat-cell *matCellDef="let element">{{ element.purpose }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <a
            [routerLink]="['/admin/profile', element.membershipID]"
            [fragment]="'paymentHistory'"
            matTooltip="Payment History"
          >
            <i class="fa fa-eye mr-3" aria-hidden="true"></i
          ></a>
          <a matTooltip="Print"> <i class="fa fa-print mr-3" aria-hidden="true"></i></a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

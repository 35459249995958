import { AlertService } from './../services/alert.service';
import { AdminService } from './../services/admin.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  role: string;
  messagesCount: any;
  messages: any;
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor( private adminService: AdminService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    const membershipId = localStorage.getItem("memberid");
    this.adminService.getAllNotifications(membershipId).subscribe(result => {
      this.messagesCount = result.length;
      console.log(this.messagesCount);
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../models/endpoints.enum';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  environment: any = environment;
  private renewUpgradePaymentURL = `${environment.api}${Endpoints.renewUpgradePayment}`;

  constructor(private httpClient: HttpClient) {}

    postRenewUpgradePaymentData(status: any) {
      return this.httpClient.post(this.renewUpgradePaymentURL, status)
    
  }
}

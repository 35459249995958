<div class="container-fluid bg-light px-5 pb-5 ml-0">
  <h3 class="ml-0 title">Dashboard</h3>

  <div class="row  cards">
    <div
      class="col-lg-3 c1 ml-0 pl-0"
      *ngIf="role === 'SECRETARY' || role === 'PRESIDENT'"
    >
      <div
        class="card-counter primary"
        style="cursor: pointer"
        routerLink="/admin/members"
      >
        <div class="count-name">Active Members</div>

        <div class="count-numbers">
          {{ activemembers }}
        </div>
      </div>
    </div>

    <div class="col-lg-3 c1 ml-0 pl-0" *ngIf="role === 'TREASURER'">
      <div
        class="card-counter primary"
        style="cursor: pointer"
        routerLink="/admin/payments"
      >
        <div class="count-name">Pending Payments</div>

        <div class="count-numbers">$ {{ pendingFunds }}</div>
      </div>
    </div>

    <div class="col-lg-3 c1 ml-0 pl-0" *ngIf="role === 'TREASURER'">
      <div class="card-counter primary">
        <div class="count-name">
          Monthly Received Funds<br />
          <span>({{ date | date: "MMM-yyyy" }})</span>
        </div>
        <div class="count-numbers funds-styles">${{ receivedFunds }}</div>
      </div>
    </div>

    <div class="col-lg-3 c1 ml-0 pl-0" *ngIf="role === 'TREASURER'">
      <div class="card-counter primary">
        <div class="count-name">Funds Recieved Today</div>
        <div class="count-numbers">${{ fundsReceivedForDay }}</div>
      </div>
    </div>

    <div class="col-lg-3 c1 pl-0">
      <div
        class="card-counter primary"
        (click)="scroll(table)"
        style="cursor: pointer"
      >
        <div class="count-name">Pending for Approvals</div>

        <div class="count-numbers">
          {{ pendingrequests }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 upcomingEvent-styles">
      <div class="card bg-light mb-3 my-5">
        <div class="card-header">
          <i class="fa fa-volume-up my-1 mr-3"></i>Upcoming Events
        </div>
        <div class="card-body">
          <div class="row" *ngFor="let event of eventsdata; let i = index">
            <div class="col-lg-7">
              <p>{{ event.eventName }}</p>
            </div>
            <div class="col-lg-5 announceDate mr-0 text-right">
              <p>{{ event.eventDate }}</p>
            </div>
          </div>
          <p class="float-right"><a routerLink="/admin/events">more...</a></p>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card bg-light mb-3 my-5">
        <div class="card-header">
          <i class="fa fa-volume-up my-1 mr-3"></i>Announcements
        </div>
        <div class="card-body">
          <div
            class="row"
            *ngFor="let announcement of announcementsdata; let i = index"
          >
            <div class="col-lg-7">
              <p>{{ announcement.eventName }}</p>
            </div>
            <div class="col-lg-5 date mr-0 text-right">
              <p>{{ announcement.eventDate }}</p>
            </div>
          </div>
          <p class="float-right mr-1">
            <a routerLink="/admin/events">more...</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <span *ngIf="this.showAlertMessage" style="color: red">
    <h5>{{ this.showAlertMessage }}</h5>
  </span>
  <span *ngIf="this.showSuccessMessage" style="color: green">
    <h5>{{ this.showSuccessMessage }}</h5>
  </span>

  <div class="row search-style">
    <div class="col-lg-8">
      <h4>Membership Requests</h4>
    </div>
    <div class="input-group col-md-4 float-right pr-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search"
        [(ngModel)]="searchInput"
        (keyup)="applyFilter()"
        style="border-radius: 0"
      />
      <button class="btn btn-success" style="width: 6rem">
        <span>Search</span>
      </button>
    </div>
  </div>

  <div class="mat-elevation-z8 mt-3 table-styles" #table>
    <table mat-table [dataSource]="dataSource">
      <!-- Applied Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Applied Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.joinDate | date: "dd-MMM-yyyy" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- role Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Application Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="color: #6d0005; cursor: pointer"
          (click)="showProfile(element.memberID)"
        >
          {{ element.userStatus }}
        </td>
      </ng-container>

      <!-- phoneNumber Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone Number</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <!-- mail Column -->
      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef>Mail</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-inline-flex">
            <button
              class="btn btn-sm"
              *ngIf="role !== 'PRESIDENT'"
              matTooltip="Approve Membership Request"
              mat-raised-button
              (click)="
                accept(
                  element.approvalId,
                  element.memberID,
                  element.userApprovalLevel,
                  element.email
                )
              "
              style="background-color: #099904; color: white"
            >
              <i class="fa fa-check mb-2" aria-hidden="true"></i>
            </button>

            <button
              class="btn btn-sm"
              mat-raised-button
              (click)="showProfile(element.memberID)"
              matTooltip="View Profile"
              style="background-color: #ffae13; margin-left: 0.5rem"
            >
              <i class="fa fa-eye mb-2" aria-hidden="true"></i>
            </button>

            <button
              class="btn btn-sm"
              *ngIf="role !== 'PRESIDENT'"
              mat-raised-button
              matTooltip="Reject Membership Request"
              (click)="
                reject(
                  element.approvalId,
                  element.memberID,
                  element.userApprovalLevel,
                  element.email
                )
              "
              style="background: #6d0005; color: white; margin-left: 0.5rem"
            >
              <i class="fa fa-times mb-2" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <span
      *ngIf="this.showNoRecordsForApprove"
      style="width: 100%; text-align: center; color: #6d0005"
    >
      <h4>{{ this.showNoRecordsForApprove }}</h4>
    </span>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

import { TestService } from "./../test.service";
import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AdminService } from "../services/admin.service";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = [
    "membershipID",
    "memberName",
    "paymentStatus",
    "paymentsReceiveDate",
    "amount",
    "issueDate",
    "purpose",
    "action",
  ];
  role: string;


  dataSource: MatTableDataSource<PaymentData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private test: TestService, private adminService: AdminService) {
    this.dataSource = new MatTableDataSource<PaymentData>();
  }

  searchInput: string;
  receivedFunds: number;
  pendingFunds: number;
  totalGatheredFunds: number;
  membershipId: string;
  date= new Date();
  fundsReceivedForDay: number;

  applyFilter() {
    this.dataSource.filter = this.searchInput.trim().toLowerCase();
  }

  testdata: any;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.adminService.getAllFunds().subscribe((result) => {
      this.pendingFunds = result.pendingFunds;
      this.receivedFunds = result.receivedFunds;
      this.totalGatheredFunds = result.totalGatheredFunds;
    });
    this.adminService.getFundsForToday().subscribe((result1) => {
      console.log(result1);
      this.fundsReceivedForDay = result1;
    });
    this.membershipId = localStorage.getItem("memberid");
    this.role = localStorage.getItem("role");
    console.log("hi" + this.membershipId)
    this.adminService.getAllPaymentsInfo().subscribe((result) => {
      this.dataSource.data = result.filter(
        (x) => x.membershipID != this.membershipId
      );
    });
  }
}

export interface PaymentData {
  membershipID: string;
  memberName: string;
  paymentStatus: string;
  paymentsReceiveDate: string;
  amount: string;
  issueDate: string;
  expiryDate: string;
  purpose: string;
  action: string;

}

<div class="container-fluid d-inline-flex">
  <div class="side-nav col-md-12">
    <div class="d-inline-flex my-3 cursor-pointer" style="width: 100%" >
      <img
        src="../../assets/kcas_logo.png"
        alt="Logo"
        style="height: 3rem; width: 3rem; margin-left: 0.5rem"
      />
      <h3 class="my-auto">KCAS</h3>
    </div>
    <ul class="mx-auto">
      <li class="my-2">
        <a routerLink="/admin" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          ><span
            ><i class="fa fa-cubes mx-2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            aria-hidden="true"></i></span
            >Dashboard
        </a>
      </li>
      <li class="my-2" *ngIf="role === 'TREASURER'">
        <a routerLink="/admin/payments" routerLinkActive="active"
          ><span><i class="fa fa-dollar mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Payments</a
        >
      </li>
      <li class="my-2">
        <a [routerLink]="['/admin/members', 'all']" routerLinkActive="active">
          <span><i class="fa fa-users mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Members</a
        >
      </li>

      <li class="my-2">
        <a routerLink="/admin/events" routerLinkActive="active"
          ><span><i class="fa fa-coffee mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Events</a
        >
      </li>
      <li class="my-2">
        <a routerLink="/admin/reports" routerLinkActive="active"
          ><span><i class="fa fa-volume-up mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Reports</a
        >
      </li>
      <li class="my-2">
        <a routerLink="/admin/profile" routerLinkActive="active"
          ><span><i class="fa fa-smile-o mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Profile</a
        >
      </li>
      <li class="my-2">
        <a routerLink="/admin/donate" routerLinkActive="active"
          ><span><i class="fa fa-tint mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Donate</a
        >
      </li>
      <li class="my-2" *ngIf="role === 'PRESIDENT'">
        <a routerLink="/admin/admin-center" routerLinkActive="active"
          ><span><i class="fa fa-cog mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span> Admin Center</a
        >
      </li>
      <li class="my-2"  *ngIf="role === 'PRESIDENT'">
        <a routerLink="/admin/role-change" routerLinkActive="active"
          ><span><i class="fa fa-users mx-2" routerLinkActive="active"
            aria-hidden="true"></i></span>Change Roles</a
        >
      </li>
    </ul>
  </div>
</div>

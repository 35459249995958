import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DonateService } from '../services/donate.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  cause = [
    { name: 'Support Telugu Language Enrichment ',
      value: 'Support Telugu Language Enrichment'},
    { name: 'Social Services', value: 'Social Services'},
    { name: 'Healthy Lifestyle', value: 'Healthy Lifestyle'},
    { name: 'Cultural Cohesive', value: 'Cultural Cohesive'},
    { name: 'Sports', value: 'Sports'},
    { name: 'Devotional Support', value: 'Devotional Support'}
  ];

  selectedValue: string;
  donation = [
    { name: '20', value: 20 },
    { name: '50', value: 50 },
    { name: '100', value: 100 },
    { name: '200', value: 200 },
    { name: '500', value: 500 },
    { name: 'Other Amount', value: ''},
  ];

  anonymous = [
    {name: 'yes', value: 'Yes'},
    {name: 'no', value: 'No'}
  ];

  donateFor = [
    { name: '5', value: '5 KM' },
    { name: '10', value: '10 KM' },
    { name: '15', value: '15 KM' },
    { name: '20', value: '20 KM' }
  ];

  donateForValue: string;
  selectedAmount = 20;
  radioSelected = 20;
  isSelected = this.anonymous[1].value;

  membershipID: string;
  transactionRef: string="";
  amount: string;
  paymentReferenceId: string;


  constructor( private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private donateService: DonateService,
    private route: ActivatedRoute,
    private router: Router,) {}

  ngOnInit(): void {
    this.membershipID = localStorage.getItem('memberid');
  }

  onSubmit(){

    if(this.transactionRef!=="" || this.transactionRef===null){

    const data = {
      membershipID: this.membershipID,
      amount: this.selectedAmount,
      paymentReferenceId: this.transactionRef
    }
    this.donateService.postDonationData(data).subscribe((postData) =>{
      console.log(postData);
      this.router.navigate(['/admin/thankyou-payment']);
      });
    }

    else{
      { this.snackBar.open("Please enter valid Transaction ID", 
        this.transactionRef, {
          duration: 2000,
        });
      }
    }
  }


  onItemChange(item) {
    this.radioSelected = item.value;
    this.selectedAmount = item.value;
  }

  onChange(item) {
    this.isSelected = item.value;
  }

  selected(value: any) {
    if (value) {
      return this.cause.find(cause => cause.name = value)['value']
    }
   // console.log(value);
    return '';
  }
  donateForFn(value: any) {
    if (value) {
      // console.log(value);
      return this.donateFor.find(donateFor => donateFor.name = value)['value']
    }

    return '';
  }
}

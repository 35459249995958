<div class="container px-5"  style="background-color: #E5E5E5;">
  <h3 class="pt-4 mobile-padding">Profile</h3>
  <div class="row px-3" style="font-weight:600">
    <div class="col-lg-12 px-0" >
      <div class="card my-2">
        <div class="card-body">

          <div class="row d-flex flex-row profile-img-div">
            <div class="w-75 pt-3 pl-3">
              <h4 class="mt-0 topRow">Membership Info of
                <span class="firstname text-capitalize">{{personalDetails?.firstName}}</span></h4>
              <hr class="horizontal-bar2" />
            </div>
            <div class="w-25 text-left">
              <img class="card-img mb-2" src="../../assets/user.png"
              alt="Card image" style="width:40%;">
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 mt-3 " >
              <span>Membership Type</span>
              <h6><i class="fa fa-users ml-0 mr-1 " aria-hidden="true"></i>
                {{personalDetails?.membershipIDType}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Membership ID</span>
              <h6>{{personalDetails?.membershipID}}</h6>
            </div>

            <div class="col-lg-3 mt-3" >
              <span>Membership Status</span>
              <h6>{{personalDetails?.memberStatus}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <div class="col-lg-3 buttonsdiv">
                <button class="btn btn-warning upgradeBtn"
                *ngIf="this.isUpgradeBtnShown" mat-raised-button
                routerLink="/admin/renew-payment">Upgrade
                </button>
                <button class="btn btn-warning mt-2" mat-raised-button
                *ngIf="this.isUpgradeBtnShown ||
                (role==='SECRETARY' && memberShipId!==null)"
                style="background-color: #6D0005;color: white;"
                [routerLink]="['/admin/edit-profile', memberShipId]">
                Edit Profile
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>Introduced Member1 ID</span>
              <h6>{{personalDetails?.membershipOne}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Introduced Member2 ID</span>
              <h6>{{personalDetails?.membershipTwo}}</h6>
            </div>

            <div class="col-lg-3 mt-3">
              <span>Membership Expiry Date</span>
              <h6>{{personalDetails?.renewdOn | date:'dd-MMM-yyyy'}}</h6>
            </div>


          </div>

          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>Introduced Member1 Name</span>
              <h6>{{personalDetails?.membershipOneName}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Introduced Member2 Name</span>
              <h6>{{personalDetails?.membershipTwoName}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
            </div>
          </div>

          <h4>Personal Details</h4>
          <hr class="horizontal-bar2" />
          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>First Name</span>
              <h6>{{personalDetails?.firstName}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Gender</span>
              <h6>{{personalDetails?.gender}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Gotram</span>
              <h6>{{personalDetails?.gotram}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Birthday</span>
              <h6>{{personalDetails?.dateOfBirth | date: 'dd-MMM-yyyy'}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>NRIC/FIN</span>
              <h6>{{personalDetails?.fin}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Preferred Name</span>
              <h6>{{personalDetails?.preferredName}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>SG Address</span>
              <h6>{{personalDetails?.sgAddress}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>Mobile Number</span>
              <h6>{{personalDetails?.mobileNo}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Email ID</span>
              <h6>{{personalDetails?.email}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Nationality*</span>
              <h6>{{personalDetails?.nationality}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Profession*</span>
              <h6>{{personalDetails?.profession}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 mt-3" >
              <span>Gotram</span>
              <h6>{{personalDetails?.gotram}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Industry </span>
              <h6>{{personalDetails?.industry}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>India Address</span>
              <h6>{{personalDetails?.indiaAddress}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Native Place/City</span>
              <h6>{{personalDetails?.nativePlace}}</h6>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-lg-3 mt-3" >
              <span>Blood Group</span>
              <h6>{{personalDetails?.bloodGroup}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Emergency contact in India</span>
              <h6>{{personalDetails?.emergencyContactInIndia}}</h6>
            </div>
            <div class="col-lg-3 mt-3" >
              <span>Relative Staying In SG</span>
              <h6>{{personalDetails?.relativeStayInSG}}</h6>
            </div>
          </div>

          <div class="spouse-div" *ngIf="personalDetails?.membershipIDType==='familyLife'
          ||personalDetails?.membershipIDType==='familyAnnual'">
            <hr class="my-3">
            <h4>Spouse  {{personalDetails?.membershipIDType}}</h4>
            <hr class="horizontal-bar2" />
            <div class="row">
              <div class="col-lg-3 mt-3" >
                <span>First Name</span>
                <h6>{{personalDetails?.firstNameSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Surname</span>
                <h6>{{personalDetails?.surnameSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Preferred Name</span>
                <h6>{{personalDetails?.preferredNameSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Birthday</span>
                <h6>{{personalDetails?.dateOfBirthSpouse | date:'dd-MMM-yyyy'}}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 mt-3" >
                <span>NRIC/FIN</span>
                <h6>{{personalDetails?.finSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Mobile Number</span>
                <h6>{{personalDetails?.mobileNoSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Email ID</span>
                <h6>{{personalDetails?.emailSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Nationality*</span>
                <h6>{{personalDetails?.nationalitySpouse}}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 mt-3" >
                <span>Profession*</span>
                <h6>{{personalDetails?.professionSpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Industry</span>
                <h6>{{personalDetails?.industrySpouse}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>India Address</span>
                <h6>{{personalDetails?.indiaAddress}}</h6>
              </div>
              <div class="col-lg-3 mt-3" >
                <span>Native Place/City</span>
                <h6>{{personalDetails?.nativePlaceSpouse}}</h6>
              </div>
            </div>
            <div class="row mb-5" >
              <div class="col-lg-3 mt-3" >
                <span>Blood Group*</span>
                <h6>{{personalDetails?.bloodGroupSpouse}}</h6>
              </div>
            </div>
          </div>

          <div class="child-div" *ngIf="personalDetails?.membershipIDType==='familyLife'
          ||personalDetails?.membershipIDType==='familyAnnual'">
            <hr class="my-3">
            <h4 *ngIf="">Child</h4>
            <hr class="horizontal-bar2" />
            <div>
              <ng-container *ngFor = "let childDetails of personalDetails?.userDependants">

                <div class="row">
                  <div class="col-lg-3 mt-3" >
                    <span>First Name</span>
                    <h6>{{childDetails?.firstname}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>Surname</span>
                    <h6>{{childDetails?.surname}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>Gender</span>
                    <h6>{{childDetails?.gender}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>Birthday</span>
                    <h6>{{childDetails?.dateOfBirth | date: 'dd-MMM-yyyy'}}</h6>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-3 mt-3" >
                    <span>NRIC/FIN</span>
                    <h6>{{childDetails?.fin}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>Student Class</span>
                    <h6>{{childDetails?.classstandard}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>School/College/University Name</span>
                    <h6>{{childDetails?.university}}</h6>
                  </div>
                  <div class="col-lg-3 mt-3" >
                    <span>Blood Group*</span>
                    <h6>{{childDetails?.bloodGroup}}</h6>
                  </div>
                </div>

                  <div class="row">

                    <div class="col-lg-3 mt-3">
                      <span>NRIC/FIN</span>
                      <h6>{{childDetails?.fin}}</h6>
                    </div>
                    <div class="col-lg-3 mt-3">
                      <span>Student Class</span>
                      <h6>{{childDetails?.classstandard}}</h6>
                    </div>
                    <div class="col-lg-3 mt-3">
                      <span>School/College/University Name</span>
                      <h6>{{childDetails?.university}}</h6>
                    </div>
                    <div class="col-lg-3 mt-3">
                      <span>Blood Group*</span>
                      <h6>{{childDetails?.bloodGroup}}</h6>
                    </div>
                  </div>

              </ng-container>
            </div>
          </div>

          <hr class="my-3">

      <div class="d-flex flex-row justify-content-center my-3"
      >
        <div class="">
          <button class="btn btn-sm btn-success save mr-2"
          mat-raised-button
          routerLink="/admin/" style="background-color: #6D0005;"
          type="submit">Back</button>
        </div>
        <!-- <div class="">
          <button class="btn btn-sm btn-danger cancel ml-2"
          type="button"
          routerLink="/admin"
          >Reject</button>
        </div> -->
      </div>
        </div>
      </div>
    </div>


  </div>


<section id="paymentHistory">
  <div class="row px-3">
    <div class="col-lg-12 my-5 px-0">
    <h3>Member Payment <span class="payment">History</span></h3>
  <div class="table-responsive  mt-3" style="background-color: white;">
    <table class="table" >
      <thead>
        <tr class="display-inline text-center">
          <th scope="col">S.No</th>
          <th scope="col">Payment Type</th>
          <th scope="col">Amount</th>
          <th scope="col">Payment Status</th>
          <th scope="col">Membership Status</th>
          <th scope="col">Payment Date</th>
          <!-- <th scope="col">Expiry Date</th> -->
          <!-- <th scope="col" *ngIf="this.isUpgradeBtnShown">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let paymentHistory of personalDetails?.userPaymentDetl;"
        class="display-inline text-center">
          <td scope="row">{{paymentHistory.id}}.</td>
          <td>{{paymentHistory.purpose}}</td>
          <td>{{paymentHistory.amount | currency}}</td>
          <td class="mr-0">

             <ng-container *ngIf="paymentHistory?.paymentStatus== 'NOT-PAID';
            then danger ; else warnings">
            </ng-container>
            <ng-template #danger>
              <p class= "status"
              style="background-color: #6D0005;color: white;"
              >{{paymentHistory?.paymentStatus}}</p>
            </ng-template>
              <ng-template #warnings>
                <p class= "status"
                style="background-color:green;color: white;">
                  {{paymentHistory?.paymentStatus}}</p>
              </ng-template>
          </td>
          <td style="color: #2D9CDB;">{{paymentHistory?.membershipStatus}}</td>
          <td>{{paymentHistory?.issueDate | date: 'dd-MMM-yyyy'}}</td>
          <!-- <td>{{paymentHistory?.expiryDate | date: 'dd-MMM-yyyy'}}</td> -->
          <!-- <td >
            <div class="action" *ngIf="this.isUpgradeBtnShown">
              <ng-template #upgrade>
                <button class= "btn btn-sm btn-gray w-50"
                routerLink="/member/renew-payment"
                type="button">Upgrade</button>
                <button class= "btn btn-sm btn-warning w-50 "
                routerLink="/member/renew-payment" type="button">
                {{paymentHistory?.action}}</button>
              </ng-template>
            </div>
            </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>
</section>
</div>


<div class="container-fluid bg-light px-5 pt-3">
  <div class="d-flex pt-0 title">
    <h3>Notifications</h3>
  </div>

  <div
    class="
      container
      d-flex
      flex-column
      py-3
      overflow-auto
      position-relative
      pt-5
    "
  >
    <div class="button-styles">
      <button
      mat-raised-button
      class="position-absolute mark-all"
      (click)="markAllAsRead()"
    >
      Mark all as Read
    </button>
    </div>
    <div
      class="d-flex message mb-3 p-3 w-75"
      [class.read]="message.status === 'read'"
      (click)="message.status !== 'read' ? read(message.id) : ''"
      *ngFor="let message of messages"
    >
      <div>
        <img src="../../assets/avatar.png" width="50" height="50" />
      </div>
      <div class="cursor-pointer ml-3">
        {{ message.message }}
      </div>
    </div>
  </div>

  <!-- <div class="container d-inline-flex py-3 ">
    <div class="col-md-4 overflow-auto">
      <div class="actions p-2 mb-1" routerLinkActive="active" 
      [routerLink]="['/admin/messages/',message.id]" *ngFor="let message of testdata">
        <a ><h4>{{message.title}}</h4>
        <p><span>1 day ago</span></p></a>
      </div>
    </div>

    <div class="col-8">
      <h3 class="text-center mb-3">{{message?.body}}</h3>
      <mat-form-field appearance="fill" id="description">
        <textarea id="eventdescription" matInput></textarea>
        <mat-label>Description</mat-label>
      </mat-form-field>
  
      <div class="buttons">
        <button class="btn btn-success mx-auto" (click)="approve()">Approve</button>
        <button class="btn btn-warning mx-auto">Review</button>
        <button class="btn btn-danger  mx-auto" style="background: #6D0005;"
        (click)="deny()">Deny</button>
      </div>
    </div>
  </div> -->
</div>

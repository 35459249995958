<nav class="navbar navbar-expand-lg mx-auto" style="background-color: #8f1313">
  <div class="text-center">
    <a class="navbar-brand">
      <img src="./assets/kcas_logo.png" width="75px" height="75px" />
      <b class="mx-3 mt-2">KCAS</b></a
    >
  </div>
  <div>
    <a routerLink="/" style="color: #ffae13; font-weight: 700">SignIn</a>
  </div>
</nav>

<div class="container">
  <fieldset>
    <legend class="my-2 py-5 text-center">Membership Application Form</legend>
    <div class="row">
      <div class="col-lg-6">
        <h3 class="mt-4">Membership <span class="info">Info</span></h3>
        <div class="line"></div>
        <p style="font-size: 0.8rem">
          *Please select the Membership type, You'll be asked to provide
          Information accordingly.
        </p>
        <div class="form-group">
          <label for="exampleSelect1">Membership Type</label>
          <ng-select
            placeholder="Select Membership Type"
            id="exampleSelect1"
            (ngModelChange)="valueChanged($event)"
            [(ngModel)]="selectedValue"
            name="memberShipType"
          >
            <ng-option
              [value]="memberType.name"
              *ngFor="let memberType of memberTypes"
              >{{ memberType.val }}</ng-option
            >
          </ng-select>
        </div>
        <div class="form-group">
          <label for="inputLarge">Referred by (Member One)</label>
          <input
            class="form-control form-control-lg"
            type="text"
            placeholder="Referee1 Name"
            id="inputLarge"
            name="membershipOne"
            [(ngModel)]="membershipName1"
          />
        </div>
        <div class="form-group">
          <label for="inputLarge">Referred by (Member Two)</label>
          <input
            class="form-control form-control-lg"
            type="text"
            placeholder="Referee2 Name"
            name="membershipTwo"
            id="inputLarge"
            [(ngModel)]="membershipName2"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <h2 class="py-4 mx-0 amount" *ngIf="selectedValue == 'singleAnnual'">
            Membership Fee <span class="redColor">$25.00</span>
          </h2>
          <h2 class="py-4 mx-0 amount" *ngIf="selectedValue == 'singleLife'">
            Membership Fee <span class="redColor">$150.00</span>
          </h2>
          <h2 class="py-4 mx-0 amount" *ngIf="selectedValue == 'familyAnnual'">
            Membership Fee <span class="redColor">$45.00</span>
          </h2>
          <h2 class="py-4 mx-0 amount" *ngIf="selectedValue == 'familyLife'">
            Membership Fee <span class="redColor">$250.00</span>
          </h2>
        </div>
        <div class="form-group">
          <p style="font-size: 0.8rem">
            *Thanks for your Interest. At KCAS, We'll accept Memberships only
            through Referrals. Please provide your referee's details to start
            Signing Up!
          </p>
          <label for="inputLarge"
            >Membership ID 1<span class="validate">*</span></label
          >
          <input
            class="form-control form-control-lg"
            type="text"
            placeholder="Referee1 Id"
            id="mebership_id1"
            name="membershipOne"
            [(ngModel)]="membershipOne"
            (blur)="validateId1($event)"
          />

          <div *ngIf="this.id1ValidateErrorMessage" class="error-text">
            {{ this.id1ValidateErrorMessage }}
          </div>
        </div>

        <div class="form-group">
          <label for="inputLarge"
            >Membership ID 2<span class="validate">*</span></label
          >
          <input
            class="form-control form-control-lg"
            type="text"
            placeholder="Referee2 Id"
            id="membership_id2"
            [(ngModel)]="membershipTwo"
            (blur)="validateId2($event)"
          />
          <div *ngIf="this.id2ValidateErrorMessage" class="error-text">
            {{ this.id2ValidateErrorMessage }}
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <ngb-tabset class="tabset1" #tabset1="ngbTabset">
    <ngb-tab title="Personal Details" id="tabOne">
      <ng-template ngbTabContent>
        <form
          id="form2"
          [formGroup]="membershipForm"
          (ngSubmit)="onSubmit('tabTwo')"
        >
          <h3 class="mt-5 mb-3">Personal <span class="info">Info</span></h3>
          <div class="line"></div>
          <div class="row">
            <div class="col-lg-6 pb-5 pt-2">
              <div class="form-group">
                <label for="firstName"
                  >Your Name<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter Your Name"
                  formControlName="firstName"
                  [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                  id="firstName"
                />
                <div
                  *ngIf="submitted && f.firstName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.firstName.errors.required">Name required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="preferredName">Preferred Name</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  formControlName="preferredName"
                  placeholder="Enter Preferred Name"
                  id="preferredName"
                />
              </div>

              <div class="form-group">
                <label for="birthday"
                  >Birthday<span class="validate">*</span></label
                >
                <input
                  type="date"
                  id="birthday"
                  formControlName="dateOfBirth"
                  name="dateOfBirth"
                  class="form-control form-control-lg"
                  [ngClass]="{ 'is-invalid': submitted && f.fin.errors }"
                />
                <div
                  *ngIf="submitted && f.dateOfBirth.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.dateOfBirth.errors.required">
                    Date of Birth is required
                  </div>
                </div>

                <!-- <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Birthday<span class="validate">*</span></mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="dateOfBirth"
                  name="dateOfBirth" id="birthday"
                  [ngClass]="{ 'is-invalid': submitted && f.fin.errors }"/>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
                    <div *ngIf="f.dateOfBirth.errors.required">
                      Date of Birth is required
                    </div>
                  </div>
                </mat-form-field> -->
              </div>

              <div class="form-group">
                <label for="fin">NRIC/FIN<span class="validate">*</span></label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  formControlName="fin"
                  [ngClass]="{ 'is-invalid': submitted && f.fin.errors }"
                  placeholder="Please enter last 4 digits of your NRIC/FIN"
                  id="fin"
                />
                {{ f.fin.errors }}
                <div *ngIf="submitted && f.fin.errors" class="invalid-feedback">
                  <div *ngIf="f.fin.errors.required">
                    NRIC/FIN Name is required
                  </div>
                  <div *ngIf="f.fin.errors.maxLength || f.fin.errors.minLength">
                    Please enter last 4 digits of your NRIC/FIN
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="mobileNumber"
                  >Mobile Number<span class="validate">*</span></label
                >
                <input
                  type="tel"
                  placeholder="SG Mobile No. (8 Digits)"
                  restrict-to="[0-9]"
                  class="form-control form-control-lg"
                  formControlName="mobileNo"
                />
              </div>
              <div class="form-group">
                <label for="nationality"
                  >Nationality<span class="validate">*</span></label
                >
                <ng-select
                  [items]="countryOptions"
                  bindLabel="name"
                  placeholder="Select Nationality"
                  id="nationality"
                  bindValue="value"
                  formControlName="nationality"
                  [ngClass]="{
                    'is-invalid': submitted && f.nationality.errors
                  }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && f.nationality.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.nationality.errors.required">
                    Nationality is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="industry">Industry</label>
                <ng-select
                  [items]="industry"
                  placeholder="Select Industry"
                  id="industry"
                  bindLabel="name"
                  bindValue="value"
                  formControlName="industry"
                >
                </ng-select>
              </div>
              <div class="form-group">
                <label for="nativeplace">Native Place/City</label>
                <ng-select
                  [items]="nativePlaceArray"
                  placeholder="Select Native Place/City"
                  id="nativeplace"
                  bindLabel="name"
                  bindValue="value"
                  formControlName="nativePlace"
                >
                </ng-select>
              </div>
              <div class="form-group">
                <label for="emergencyNo"
                  >Emergency Contact in India<span class="validate"
                    >*</span
                  ></label
                >
                <input
                  type="tel"
                  class="form-control form-control-lg"
                  placeholder="10 digit Mobile number"
                  formControlName="emergencyContactInIndia"
                />
                <p style="font-size: 0.8rem">
                  *Please provide valid Indian contact, So that we can contact
                  in case of any Emergency.
                </p>
              </div>
            </div>
            <div class="col-lg-6 pt-2 pb-5">
              <div class="form-group">
                <label for="surName"
                  >Surname<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter Surname"
                  id="surname"
                  formControlName="surName"
                  [ngClass]="{ 'is-invalid': submitted && f.surName.errors }"
                />
                <div
                  *ngIf="submitted && f.surName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.surName.errors.required">Surname Required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="gotram"
                  >Gotram<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter Gotram"
                  id="gotram"
                  formControlName="gotram"
                  [ngClass]="{ 'is-invalid': submitted && f.gotram.errors }"
                />
                <div
                  *ngIf="submitted && f.gotram.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.gotram.errors.required">Gotram required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="gender"
                  >Gender<span class="validate">*</span></label
                >
                <ng-select
                  [items]="genderValues"
                  placeholder="Select Gender"
                  id="gender"
                  bindLabel="name"
                  bindValue="value"
                  formControlName="gender"
                  [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && f.gender.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.gender.errors.required">Gender required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="sgAddress"
                  >SG Address<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter SG Address"
                  id="sgAddress"
                  formControlName="sgAddress"
                  [ngClass]="{ 'is-invalid': submitted && f.sgAddress.errors }"
                />
                <div
                  *ngIf="submitted && f.sgAddress.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.sgAddress.errors.required">
                    SG Address required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="emailID"
                  >E-mail ID<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="email"
                  placeholder="Enter Email ID"
                  id="emailID"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  (blur)="validateEmail($event.value)"
                />
                <div *ngIf="this.emailIdValidation" class="error-text">
                  {{ this.emailIdValidation }}
                </div>

                <div
                  *ngIf="submitted && f.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.email.errors.required">Email required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="profession"
                  >Profession<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter Profession"
                  id="profession"
                  formControlName="profession"
                  [ngClass]="{ 'is-invalid': submitted && f.profession.errors }"
                />
                <div
                  *ngIf="submitted && f.profession.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.profession.errors.required">
                    Profession required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="indiaAddr">India Address</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter India Address"
                  id="indiaAddr"
                  formControlName="indiaAddress"
                />
              </div>
              <div class="form-group">
                <label for="bloodGroup"
                  >Blood Group<span class="validate">*</span></label
                >
                <ng-select
                  [items]="blGroup"
                  bindLabel="name"
                  placeholder="Select Blood Group"
                  id="bloodGroup"
                  bindValue="value"
                  formControlName="bloodGroup"
                  [ngClass]="{ 'is-invalid': submitted && f.bloodGroup.errors }"
                >
                </ng-select>
                <div
                  *ngIf="submitted && f.bloodGroup.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.bloodGroup.errors.required">
                    Blood Group required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="optradio">Relatives staying in SG?</label><br />
                <label class="radio-inline">
                  <input
                    type="radio"
                    value="yes"
                    formControlName="relativeStayInSG"
                  />
                  Yes
                </label>
                <label class="radio-inline px-5">
                  <input
                    type="radio"
                    value="no"
                    formControlName="relativeStayInSG"
                  />
                  No
                </label>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              selectedValue == 'familyAnnual' || selectedValue == 'familyLife'
            "
          >
            <div class="row">
              <h3>Spouse Details</h3>
            </div>
            <div>
              <div class="row">
                <div class="col-lg-6 pt-5">
                  <div class="form-group">
                    <label for="firstNameSpouse"
                      >First Name<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter First Name"
                      id="firstNameSpouse"
                      formControlName="firstNameSpouse"
                      [ngClass]="{
                        'is-invalid': submitted && f.firstNameSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.firstNameSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.firstNameSpouse.errors.required">
                        First Name required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="preferredNameSpouse">Preferred Name</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter Preferred Name"
                      id="preferredNameSpouse"
                      formControlName="preferredNameSpouse"
                    />
                  </div>
                  <div class="form-group">
                    <label for="finSpouse"
                      >NRIC/FIN<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter NRIC/FIN"
                      id="finSpouse"
                      formControlName="finSpouse"
                      [ngClass]="{
                        'is-invalid': submitted && f.finSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.finSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.finSpouse.errors.required">
                        NRIC/FIN required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="emailSpouse">E-mail ID</label>
                    <input
                      class="form-control form-control-lg"
                      type="email"
                      placeholder="Enter E-mail ID"
                      id="emailSpouse"
                      formControlName="emailSpouse"
                      [ngClass]="{
                        'is-invalid': submitted && f.emailSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.emailSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.emailSpouse.errors.required">
                        E-mail ID required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="professionSpouse"
                      >Profession<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter Profession"
                      id="professionSpouse"
                      formControlName="professionSpouse"
                      [ngClass]="{
                        'is-invalid': submitted && f.professionSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.professionSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.professionSpouse.errors.required">
                        Profession required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="indiaAddrSpouse">India Address</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter India Address"
                      id="indiaAddrSpouse"
                      formControlName="indiaAddressSpouse"
                    />
                  </div>
                  <div class="form-group">
                    <label for="bloodGroupSpouse"
                      >Blood Group<span class="validate">*</span></label
                    >
                    <ng-select
                      [items]="blGroup"
                      placeholder="Select Blood Group"
                      id="bloodGroup"
                      bindLabel="name"
                      formControlName="bloodGroupSpouse"
                      bindValue="value"
                      [ngClass]="{
                        'is-invalid': submitted && f.bloodGroupSpouse.errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="submitted && f.bloodGroupSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.bloodGroupSpouse.errors.required">
                        Blood Group required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pt-5 spouseColumn">
                  <div class="form-group">
                    <label for="surnameSpouse"
                      >SurName<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      placeholder="Enter Surname"
                      id="surnameSpouse"
                      formControlName="surnameSpouse"
                      [ngClass]="{
                        'is-invalid': submitted && f.surnameSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.surnameSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.surnameSpouse.errors.required">
                        Surname required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="birthday"
                      >Birthday<span class="validate">*</span></label
                    >
                    <input
                      type="date"
                      id="birthdaySpouse"
                      formControlName="dateOfBirthSpouse"
                      name="dateOfBirthSpouse"
                      class="form-control form-control-lg"
                      [ngClass]="{
                        'is-invalid': submitted && f.dateOfBirthSpouse.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.surnameSpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.surnameSpouse.errors.required">
                        Date of Birth required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="mobileNumberSpouse">Mobile Number</label>
                    <div class="input-group">
                      <input
                        type="tel"
                        placeholder="Enter Mobile Number"
                        class="form-control form-control-lg"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="nationalitySpouse"
                      >Nationality<span class="validate">*</span></label
                    >
                    <ng-select
                      [items]="countryOptions"
                      placeholder="Select Nationality"
                      bindLabel="name"
                      id="nationalitySpouse"
                      formControlName="nationalitySpouse"
                      bindValue="value"
                      [ngClass]="{
                        'is-invalid': submitted && f.nationalitySpouse.errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="submitted && f.nationalitySpouse.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.nationalitySpouse.errors.required">
                        Nationality required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="industrySpouse">Industry</label>
                    <ng-select
                      [items]="industry"
                      placeholder="Select Industry"
                      bindLabel="name"
                      bindValue="value"
                      id="industrySpouse"
                      formControlName="industrySpouse"
                    >
                    </ng-select>
                  </div>
                  <div class="form-group">
                    <label for="nativeplaceSpouse">Native Place/City</label>

                    <ng-select
                      [items]="nativePlaceArray"
                      bindLabel="name"
                      bindValue="value"
                      placeholder="Select Native Place/City"
                      id="nativeplaceSpouse"
                      formControlName="nativePlaceSpouse"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            *ngIf="
              selectedValue == 'familyAnnual' || selectedValue == 'familyLife'
            "
          >
            <div formArrayName="children">
              <div
                class="row py-5"
                [formGroupName]="i"
                *ngFor="let ar of children.controls; index as i"
              >
                <div class="col-lg-6">
                  <h4>Child - {{ i + 1 }} Details</h4>
                </div>
                <div class="col-lg-6 text-right">
                  <button
                    type="button"
                    class="btn btn-warning childBtn btn-xs"
                    (click)="removeChild(i)"
                  >
                    -
                  </button>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="firstNameChild_{{ i }}"
                      >First Name<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="firstNameChild"
                      placeholder="Enter First Name"
                      id="firstNameChild_{{ i }}"
                      name="firstNameChild_{{ i }}"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          children.controls[i].get('firstNameChild').errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted &&
                        children.controls[i].get('firstNameChild').errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          children.controls[i].get('firstNameChild').errors
                            .required
                        "
                      >
                        First Name is required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="genderChild_{{ i }}">Gender</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="genderChild"
                      placeholder="Enter Gender"
                      id="genderChild_{{ i }}"
                      name="genderChild_{{ i }}"
                    />
                  </div>
                  <div class="form-group">
                    <label for="finChild_{{ i }}"
                      >NRIC/FIN<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="finChild"
                      placeholder="Enter NRIC/FIN"
                      id="finChild_{{ i }}"
                      name="finChild_{{ i }}"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          children.controls[i].get('finChild').errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && children.controls[i].get('finChild').errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          children.controls[i].get('finChild').errors.required
                        "
                      >
                        NRIC/FIN required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="studentClassChild_{{ i }}">Student Class</label>
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="studentClassChild"
                      placeholder="Enter Student Class"
                      id="studentClassChild_{{ i }}"
                      name="studentClassChild_{{ i }}"
                    />
                  </div>
                  <div class="form-group">
                    <label for="bloodGroupChild_{{ i }}"
                      >Blood Group<span class="validate">*</span></label
                    >
                    <ng-select
                      [items]="blGroup"
                      placeholder="Select Blood Group"
                      id="bloodGroupChild"
                      formControlName="bloodGroupChild"
                      bindLabel="name"
                      bindValue="value"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          children.controls[i].get('bloodGroupChild').errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        submitted &&
                        children.controls[i].get('bloodGroupChild').errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          children.controls[i].get('bloodGroupChild').errors
                            .required
                        "
                      >
                        Select Blood group
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="surnameChild_{{ i }}"
                      >Surname<span class="validate">*</span></label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="surnameChild"
                      placeholder="Enter Surname"
                      id="surnameChild_{{ i }}"
                      name="surnameChild_{{ i }}"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          children.controls[i].get('surnameChild').errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted &&
                        children.controls[i].get('surnameChild').errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          children.controls[i].get('surnameChild').errors
                            .required
                        "
                      >
                        Surname required
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="birthday">Birthday</label>
                    <input
                      type="date"
                      id="birthdayChild"
                      formControlName="dateOfBirthChild"
                      name="dateOfBirthChild"
                      class="form-control form-control-lg"
                    />
                  </div>
                  <div class="form-group"></div>
                  <div class="form-group">
                    <label for="schoolChild_{{ i }}"
                      >School/College/University Name</label
                    >
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      formControlName="schoolChild"
                      placeholder="Enter School/College/University Name"
                      id="schoolChild_{{ i }}"
                      name="schoolChild_{{ i }}"
                    />
                  </div>

                  <div class="form-group">
                    <label for="nationalityChild"
                      >Nationality<span class="validate">*</span></label
                    >
                    <ng-select
                      [items]="countryOptions"
                      placeholder="Select Nationality"
                      id="nationalitychild_{{ i }}"
                      formControlName="nationalityChild"
                      bindLabel="name"
                      bindValue="value"
                      placeholder="Select Nationality"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          children.controls[i].get('nationalityChild').errors
                      }"
                    >
                    </ng-select>
                    <div
                      *ngIf="
                        submitted &&
                        children.controls[i].get('nationalityChild').errors
                      "
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          children.controls[i].get('nationalityChild').errors
                            .required
                        "
                      >
                        Select Nationality
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form>
              <div class="row">
                <div class="col-lg-11">
                  <hr />
                  <p>click on + button for adding a child</p>
                </div>
                <div class="col-lg-1">
                  <button
                    type="button"
                    class="btn btn-warning childBtn btn-xs"
                    (click)="createChild()"
                  >
                    +
                  </button>
                </div>
              </div>
            </form>
          </section>

          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
              <button
                routerLink="/"
                type="submit"
                class="btn btn-warning btn-lg saveBtn mr-2"
              >
                Back
              </button>

              <button type="submit" class="btn btn-warning btn-lg saveBtn ml-2">
                Next
              </button>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </form>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="General Details" id="tabTwo">
      <ng-template ngbTabContent>
        <form [formGroup]="generalForm">
          <div class="flex-container flex-wrap">
            <div class="element">
              <label class="field-label pt-5">
                <h3>Section-A</h3>
              </label>
              <p>Support needed from KCAS Community ?</p>
              <div class="flex-container">
                <table class="table table-borderless">
                  <tr>
                    <td><span>Main Applicant: </span></td>
                    <td
                      *ngFor="
                        let applicantChoice of applicantChoices;
                        let i = index
                      "
                    >
                      <div class="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="exampleCheck1"
                          name="careers"
                          [value]="applicantChoice.value"
                          class="chkBox form-check-input"
                          (change)="onCheckboxChangeApplicant($event)"
                        />
                        <label class="form-check-label" for="exampleCheck1">{{
                          applicantChoice.name
                        }}</label>
                      </div>
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      selectedValue == 'familyAnnual' ||
                      selectedValue == 'familyLife'
                    "
                  >
                    <td><span class="mr-5 spouse">Spouse: </span></td>

                    <td
                      *ngFor="let spouseChoice of spouseChoices; let i = index"
                    >
                      <div class="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="exampleCheck4"
                          name="arts"
                          class="chkBox form-check-input"
                          [value]="spouseChoice.value"
                          (change)="onCheckboxChangeSpouse($event)"
                        />
                        <label class="form-check-label" for="exampleCheck4">{{
                          spouseChoice.name
                        }}</label>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr *ngIf="isChildForm">
                    <td><span class="children">Children: </span></td>
                    <td
                      *ngFor="
                        let childrenChoice of childrenChoices;
                        let i = index
                      "
                    >
                      <div class="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="exampleCheck6"
                          name="art"
                          class="chkBox form-check-input"
                          [value]="childrenChoice.value"
                          (change)="onCheckboxChangeChild($event)"
                        />
                        <label class="form-check-label" for="exampleCheck6">{{
                          childrenChoice.name
                        }}</label>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="flex-container flex-wrap">
            <div class="element">
              <label class="field-label pt-5">
                <h3>Section-B</h3>
              </label>
              <p>Would You like to contribute KCAS Community ?</p>
              <div
                class="form-check form-check-inline mr-5"
                *ngFor="let communityChoice of communityChoices; let i = index"
              >
                <input
                  type="checkbox"
                  id="exampleCheck9"
                  name="sports"
                  class="chkBox form-check-input"
                  [value]="communityChoice.value"
                  (change)="onCheckboxChangeCom($event)"
                />
                <label class="form-check-label" for="exampleCheck9">{{
                  communityChoice.name
                }}</label>
              </div>
            </div>
          </div>
          <!-- Material switch -->
          <div class="form-group">
            <p class="toggle pt-5">
              Would You like to Volunteer KCAS Activities?
              <span class="ml-5">
                <mat-slide-toggle [color]="color" (change)="onChange1($event)">
                </mat-slide-toggle>
              </span>
            </p>
          </div>
          <div class="form-group">
            <p class="toggle">
              Would You like to join KCAS Activities?
              <span class="ml-5">
                <mat-slide-toggle
                  value="warn"
                  [color]="color"
                  (change)="onChange2($event)"
                >
                </mat-slide-toggle>
              </span>
            </p>
          </div>

          <div class="d-inline-flex">
            <mat-checkbox
              class="mt-1 mr-2"
              (change)="consentAgreement = !consentAgreement"
            >
              <p>
                By Providing us your contact details, you agree to give your
                consent as per <b>PDPA</b> of Singapore.
              </p></mat-checkbox
            >
          </div>
          <div class="d-inline-flex">
            <mat-checkbox
              class="mt-1 mr-2"
              (change)="termsConditions = !termsConditions"
            >
              <p>
                By clicking on Submit you agree to
                <b>Terms and Conditions</b> of <b>KCAS</b> Singapore.
              </p></mat-checkbox
            >
          </div>

          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
              <button
                type="button"
                (click)="tabset1.select('tabOne')"
                class="btn btn-warning btn-lg mr-2 saveBtn"
              >
                Previous
              </button>

              <button
                class="btn btn-warning btn-lg ml-2 saveBtn test"
                type="submit"
                [disabled]="!consentAgreement || !termsConditions"
                (click)="onSave(); loading = true"
              >
                <span *ngIf="!loading">Submit</span>
                <span>
                  <mat-spinner
                    mode="indeterminate"
                    value="100"
                    [diameter]="25"
                    *ngIf="this.loading"
                  ></mat-spinner>
                </span>
              </button>
            </div>
            <div class="col-lg-4"></div>
          </div>
        </form>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

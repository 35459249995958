<div class="container-fluid bg-light px-5 ml-0">

  <h3 class="pt-3 title">Members <div class="input-group col-md-4 float-right pr-0">
      <input type="text" class="form-control" placeholder="Search" style="border-radius: 0;" [(ngModel)]="searchInput"
        (keyup)="applyFilter()">
      <button class="btn btn-success">
        <span>Search</span>
      </button>
    </div>
  </h3>

  <div class="mat-elevation-z8 mt-3 table-styles">
    <table mat-table [dataSource]="dataSource">

      <!-- memberID Column -->
      <ng-container matColumnDef="memberID">
        <th mat-header-cell *matHeaderCellDef> Member ID </th>
        <td mat-cell *matCellDef="let element"> {{element.memberID}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- role Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" 
        style="color: #2D9CDB;"> {{element.userStatus}} </td>
      </ng-container>

      <!-- phoneNumber Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>

      <!-- joinDate Column -->
      <ng-container matColumnDef="joinDate">
        <th mat-header-cell *matHeaderCellDef> Join Date </th>
        <td mat-cell *matCellDef="let element"> {{element.joinDate | date: 'dd-MMM-yyyy'}} </td>
      </ng-container>

      <!-- renewalDate Column -->
      <ng-container matColumnDef="renewalDate">
        <th mat-header-cell *matHeaderCellDef> Renewal Date </th>
        <td mat-cell *matCellDef="let element"> {{element.renewalDate | date: 'dd-MMM-yyyy'}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
        <td mat-cell *matCellDef="let element" class="text-center pr-0">
          <a [routerLink]="['/admin/profile', element.memberID]" matTooltip="Show Member Profile">
            <i class="fa fa-eye mr-3" aria-hidden="true"></i></a>
          <a *ngIf="role==='SECRETARY'">
            <i class="fa fa-trash-o mr-3 " aria-hidden="true"
            (click)="inactive(element.memberID)" matTooltip="Make user in-active"></i></a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20,50]" showFirstLastButtons></mat-paginator>
  </div>


</div>
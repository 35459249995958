import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { AppSingleFormComponent } from './app-single-form/app-single-form.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { AdminInboxComponent } from './admin-inbox/admin-inbox.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { EventsComponent } from './events/events.component';
import { MembersComponent } from './members/members.component';
import { ReportsComponent } from './reports/reports.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { DonateComponent } from './donate/donate.component';
import { AdminCenterComponent } from './admin-center/admin-center.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MemberLayoutComponent } from './member-layout/member-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { MemberMessagesComponent } from './member-messages/member-messages.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { MemberDonateComponent } from './member-donate/member-donate.component';
import { RenewPaymentComponent } from './renew-payment/renew-payment.component';
import { MatDialogModule } from '@angular/material/dialog';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatButtonModule } from '@angular/material/button';
import { DialogComponent } from './admin-dashboard/dialog/dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddEventDialogComponent } from './events/add-event-dialog/add-event-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { KcasHttpInterceptor } from './services/kcas.intercetpr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './spinner/spinner.component';
import { LogoutComponent } from './logout/logout.component';
import { InactiveComponent } from './members/inactive/inactive.component';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { RoleChangeComponent } from './role-change/role-change.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ApprovePaymentComponent } from './approve-payment/approve-payment.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ThankyouPaymentComponent } from './thankyou-payment/thankyou-payment.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AppSingleFormComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    routingComponents,
    ForgotPasswordComponent,
    SideNavBarComponent,
    AdminInboxComponent,
    TopNavBarComponent,
    AdminDashboardComponent,
    EventsComponent,
    MembersComponent,
    ReportsComponent,
    PaymentsComponent,
    ProfileComponent,
    DonateComponent,
    AdminCenterComponent,
    MemberLayoutComponent,
    AdminLayoutComponent,
    MemberMessagesComponent,
    MakePaymentComponent,
    MemberDonateComponent,
    RenewPaymentComponent,
    ThankYouComponent,
    DialogComponent,
    AddEventDialogComponent,
    SpinnerComponent,
    LogoutComponent,
    InactiveComponent,
    MemberProfileComponent,
    EditProfileComponent,
    RoleChangeComponent,
    ChangePasswordComponent,
    ErrorPageComponent,
    ApprovePaymentComponent,
    ThankyouPaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    UiSwitchModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatBadgeModule,
    MatIconModule,
    MatSelectModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    Ng2SearchPipeModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatStepperModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: KcasHttpInterceptor, multi: true }, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }

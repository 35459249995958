import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../models/endpoints.enum';
import { Member } from '../models/member';
import { Observable } from 'rxjs';
import { Editprofile } from '../models/editprofile';




const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class MemberServiceService {

  environment: any = environment;
  private addMemberUrl = `${environment.api}${Endpoints.addMember}`;
  private editMemberprofile = `${environment.api}${Endpoints.editMemberprofile}`;
  private url = `${environment.api}${Endpoints.getMemberPayHist}`;
  private verifyMembershipExistOrNot = `${environment.api}${Endpoints.verifyMembershipId}`;
  private verifyEmailExistOrNot = `${environment.api}${Endpoints.verifyEmailId}`;

  constructor(private httpClient: HttpClient
  ) { }

  //}
  
  verifyIntroductoryId(membershipId : string): Observable<any> {
    const  verifyUrl = `${this.verifyMembershipExistOrNot}/` +membershipId;
    return this.httpClient.get<any>(verifyUrl, httpOptions);
  }

  verifyEmail(emailId : string): Observable<any> {
    const  verifyUrl = `${this.verifyEmailExistOrNot}/` +emailId;
    return this.httpClient.get<any>(verifyUrl, httpOptions);
  }

  editMemberProfile(editMProfile: Editprofile): Observable<any> {
   
    return this.httpClient.put<Editprofile>(this.editMemberprofile, editMProfile, httpOptions);
  }

  newMember(member: any): Observable<any> {
    return this.httpClient.post<any>(this.addMemberUrl, member, httpOptions);
  }

  getMembers(membershipId: string): Observable<Member[]> {
    const urlGet = `${this.url}/` + membershipId;
    return this.httpClient.get<Member[]>(urlGet, httpOptions);
  }


}

import { PaymentsService } from './../services/payments.service';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LoginService } from '../services/login-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-renew-payment',
  templateUrl: './renew-payment.component.html',
  styleUrls: ['./renew-payment.component.scss']
})
export class RenewPaymentComponent implements OnInit {

  userName:string;
  profession: string;
  currDate = new Date();
  year: number= this.currDate.getFullYear();
  month: number= this.currDate.getMonth();
  Day: number= this.currDate.getDate();
  oneYear= new Date(this.year+1,this.month,this.Day-1);

  memberTypesForSingleAnnual = [
    { name: "singleAnnual", val: "Single Annual" },
    { name: "singleLife", val: "Single Life" },
    { name: "familyAnnual", val: "Family Annual" },
    { name: "familyLife", val: "Family Life" },
  ];

  memberTypesForSingleLife = [
    { name: "singleLife", val: "Single Life" },
    { name: "familyAnnual", val: "Family Annual" },
    { name: "familyLife", val: "Family Life" },
  ];

  memberTypesForFamilyAnnual = [
    { name: "familyAnnual", val: "Family Annual" },
    { name: "familyLife", val: "Family Life" },
  ];

  memberTypesForFamilyLife = [
    { name: "familyLife", val: "Family Life" },
  ];

  amount=
    {"singleAnnual": 25,"singleLife": 150,
    "familyAnnual": 45, "familyLife": 250}

  paymentFor = [
    {name: 'Renewal', value: 'Renewal'},
    {name: 'Upgrade', value: 'Upgradation'}
  ];

  isSelected = this.paymentFor[0].name;

  onChange(item) {
    this.isSelected = item.value;
    console.log(this.isSelected)
    if(this.isSelected === 'Renewal'){
      this.selectedValue = this.currentMembershipType;
      console.log(this.selectedValue);}

    else
      this.selectedValue = this.selectedValue;
  }

  selectedValue: string;
  currentMembershipType: any;
  memberShipId: string;
  transactionRef: string = "";
  postAmount: number;
  
  constructor(private loginService: LoginService,
    private adminService: AdminService,
    private paymentService: PaymentsService,
    private snackBar: MatSnackBar,) { }

    role: string;
    createdDate: string;
    userStatus: string;

  ngOnInit(): void {
    this.userName = localStorage.getItem("name");
    this.role = localStorage.getItem("role");
    this.createdDate = localStorage.getItem("createdon");
    this.memberShipId = localStorage.getItem("memberid");

    this.adminService.getMemberProfile(this.memberShipId).subscribe((result) => {
      this.currentMembershipType = result['membershipIDType'];
      this.userStatus = result['memberStatus'];

      if(this.isSelected === 'Renewal')
        this.selectedValue = this.currentMembershipType;
    });
  }

  onSubmit(){

    if(this.transactionRef!=="" || this.transactionRef===null){

      const data = {
        membershipID: this.memberShipId,
        amount: this.amount[this.selectedValue],
        paymentReferenceId: this.transactionRef,
        purpose: this.isSelected,
        membershipType: this.selectedValue
      }
      this.paymentService.postRenewUpgradePaymentData(data).subscribe((postData) =>{
        console.log(postData);
        { 
          this.snackBar.open("Payment Info sent Successfully to TREASURER towards Upgradation to ", 
          this.selectedValue, {
            duration: 3000,
          })}
        });
      }
  
      else{
        { this.snackBar.open("Please Enter Valid Transaction ID", 
          this.transactionRef, {
            duration: 2000,
          });
        }
      }
  }

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../models/endpoints.enum';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  environment: any = environment;
  private eventsUrl = `${environment.api}${Endpoints.events}`;

  constructor(
    private http: HttpClient
  ) { }

  postEventData(status:any) {
    return this.http.post(this.eventsUrl, status,httpOptions);
  }

  getEventData() {
    return this.http.get(this.eventsUrl,httpOptions);
  }

}

import { Component, OnInit } from "@angular/core";
import { FormArray } from "@angular/forms";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MemberServiceService } from "../services/member-service.service";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-make-payment",
  templateUrl: "./make-payment.component.html",
  styleUrls: ["./make-payment.component.scss"],
})
export class MakePaymentComponent implements OnInit {
  //Membership Info Form
  firstFormGroup: FormGroup = this.formBuilder.group({
    membershipIDType: ["", Validators.required],
    membershipOne: ["", Validators.required],
    membershipTwo: ["", Validators.required],
  });

  //Personal Info Form
  secondFormGroup: FormGroup = this.formBuilder.group({
    firstName: ["", Validators.required],
    surName: ["", Validators.required],
    gotram: ["", Validators.required],
    dateOfBirth: ["", Validators.required],
    gender: ["", Validators.required],
    bloodGroup: ["", Validators.required],
    nationality: ["", Validators.required],
    fin: [
      "",
      [Validators.required, Validators.maxLength(4), Validators.minLength(4)],
    ],
    nativePlace: "",
    preferredName: "",
    profession: ["", Validators.required],
    industry: "",
    relativeStayInSG: "No",
  });

  //Spouse + Child Info (Sub Category of PersonalForm)
  spouseFormGroup: FormGroup = this.formBuilder.group({
    firstNameSpouse: ["", Validators.required],
    surnameSpouse: ["", Validators.required],
    preferredNameSpouse: null,
    dateOfBirthSpouse: ["", Validators.required],
    emailSpouse: "",
    bloodGroupSpouse: ["", Validators.required],
    mobileNoSpouse: "",
    nationalitySpouse: "",
    finSpouse: "",
    nativePlaceSpouse: "",
    professionSpouse: "",
    industrySpouse: "",
    indiaAddressSpouse: "",
    spouse: this.formBuilder.array([]),
    child: this.formBuilder.array([]),
    children: this.formBuilder.array([]),
  });

  //Contact Info Form
  thirdFormGroup: FormGroup = this.formBuilder.group({
    email: ["", [Validators.required, Validators.email]],
    mobileNo: ["", Validators.required],
    sgAddress: ["", Validators.required],
    emergencyContactInIndia: ["", Validators.required],
    indiaAddress: ["", Validators.required],
  });

  //General Info Form
  fourthFormGroup: FormGroup = this.formBuilder.group({
    applicant: this.formBuilder.array([]),
    community: this.formBuilder.array([]),
  });

  communityServicesArray: Set<String> = new Set();

  isEditable = false;
  maxDate = new Date();
  minDate = new Date();

  isSwitchOne = false;
  isSwitchTwo = false;
  color: ThemePalette = "primary";

  consentAgreement: boolean = false;
  termsConditions: boolean = false;

  id1ValidateErrorMessage: string;
  id2ValidateErrorMessage: any;
  emailIdValidation: string;
  buttonDisabled: boolean = true;
  buttonDisabled2: boolean = true;
  buttonDisabled1: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberServiceService,
    private router: Router
  ) {
    this.maxDate.setDate(this.maxDate.getDate() - 6574); //18 yrs Validation
    this.minDate.setDate(this.maxDate.getDate()); //present and previous dates
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  onChange1(e: MatSlideToggleChange) {
    this.isSwitchOne = e.checked;
  }
  onChange2(e: MatSlideToggleChange) {
    this.isSwitchTwo = e.checked;
  }

  //Validating MemberID 1 from DB

  validateId1(id: string) {
    console.log(id);
    if (this.firstFormGroup.value.membershipOne != null) {
      this.memberService
        .verifyIntroductoryId(this.firstFormGroup.value.membershipOne)
        .subscribe((result) => {
          console.log(result);
          if (
            !result ||
            this.firstFormGroup.value.membershipOne ===
              this.firstFormGroup.value.membershipTwo
          )
            this.id1ValidateErrorMessage =
              "Please Provide Valid & Different Membership IDs";
          else {
            this.id1ValidateErrorMessage = null;
            this.buttonDisabled1 = false;
          }
        });
    }
  }

  communityContribution(type) {
    if (this.communityServicesArray.has(type))
      this.communityServicesArray.delete(type);
    else this.communityServicesArray.add(type);
  }

  //Validating MemberID 2 from DB

  validateId2(id: string) {
    console.log(id);
    if (this.firstFormGroup.value.membershipTwo != null) {
      this.memberService
        .verifyIntroductoryId(this.firstFormGroup.value.membershipTwo)
        .subscribe((result) => {
          console.log(result);
          if (
            !result ||
            this.firstFormGroup.value.membershipTwo ===
              this.firstFormGroup.value.membershipOne
          )
            this.id2ValidateErrorMessage =
              "Please Provide Valid & Different Membership IDs";
          else {
            this.id2ValidateErrorMessage = null;
            this.buttonDisabled2 = false;
          }
        });
    }
  }

  get secondFormGroupControls() {
    return this.secondFormGroup.controls;
  }

  //Validating Applicant email from DB

  validateEmail(email: string) {
    if (this.thirdFormGroup.value.email != null) {
      this.memberService
        .verifyEmail(this.thirdFormGroup.value.email)
        .subscribe((result) => {
          console.log(result);
          if (result) this.emailIdValidation = "This Email Id Already Exists.";
          else {
            this.emailIdValidation = null;
            this.buttonDisabled = false;
          }
        });
    }
  }

  ngOnInit(): void {}

  get children(): FormArray {
    return this.spouseFormGroup.get("children") as FormArray;
  }

  isChildForm = false;
  createChild() {
    this.isChildForm = true;
    const form: FormGroup = this.formBuilder.group({
      firstNameChild: ["", Validators.required],
      genderChild: [null, Validators.required],
      finChild: [null],
      studentClassChild: [],
      bloodGroupChild: [null, Validators.required],
      surnameChild: [null],
      dateOfBirthChild: [null, Validators.required],
      schoolChild: [],
      nationalityChild: [null, Validators.required],
    });
    form.value.dat;
    this.children.push(form);
  }

  removeChild(index: number) {
    if (this.children.length !== -1) {
      this.children.removeAt(index);
    } else {
      this.isChildForm = false;
    }
  }

  onSubmit() {
    var data1 = this.firstFormGroup.value;
    var data2 = this.secondFormGroup.value;
    data2.dateOfBirth = moment(data2.dateOfBirth).format("yyyy-MM-DD");
    var data3 = this.spouseFormGroup.value;
    data3.dateOfBirthSpouse = moment(data3.dateOfBirthSpouse).format(
      "yyyy-MM-DD"
    );
    data3.children = data3.children.map((child) => {
      child.dateOfBirthChild = moment(child.dateOfBirthSpouse).format(
        "yyyy-MM-DD"
      );
      return child;
    });

    var data4 = this.thirdFormGroup.value;
    var data5 = this.fourthFormGroup.value;
    var data6 = {
      isSwitchOne: this.isSwitchOne,
      isSwitchTwo: this.isSwitchTwo,
      membershipID: null,
      relativeStayInSG: this.secondFormGroup.value.relativeStayInSG,
    };

    //Combining all form Objects into One
    if (
      this.firstFormGroup.value.membershipIDType === "familyLife" ||
      "familyAnnual"
    ) {
      var data = Object.assign(data1, data2, data3, data4, data5, data6);
      data = {
        ...data,
        community: [...this.communityServicesArray],
      };
      this.memberService.newMember(data).subscribe((result) => {
        console.log(result);
        if (result !== null) this.router.navigate(["thank-you"]);
        else this.router.navigate(["error-page"]);
      });
    } else {
      var data = Object.assign(data1, data2, data4, data5, data6);
      data = {
        ...data,
        community: [...this.communityServicesArray],
      };
      this.memberService.newMember(data).subscribe(
        (result) => {
          console.log(result);
          if (result !== null) this.router.navigate(["thank-you"]);
        },
        () => {
          this.router.navigate(["error-page"]);
        }
      );
    }
  }
}

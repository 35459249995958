import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {


  constructor(public dailogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit(): void {
  }

  submit(remarks: string): void {
    this.dailogRef.close(remarks);
  }

}

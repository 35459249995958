import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private http: HttpClient) { }

  postEventData(status) {
    return this.http.post(`${environment.api}events`, status)
  }

  postDonationData(status) {
    return this.http.post(`${environment.api}donate`, status)
  }

  getEventData() {
    return this.http.get(`${environment.api}events`)
  }

  changePassword(status){
    return this.http.post(`${environment.api}changePwd`, status)
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private loginService: LoginService,

    private router: Router) { }

  ngOnInit(): void {
    localStorage.clear();
    this.loginService.getAutheToken = null;
    this.router.navigate(['/']);
  }

}

<div class="container-fluid pt-5 px-5">
  <div class="row">
    <div class="col-md-4">
      <h3>Change <span class="info"> Password</span></h3>
      <hr class="horizontal-bar2" />
    </div>
    <div class="col-md-4 text-center">
      <form [formGroup]="formVariable" (ngSubmit)="onSubmit()">

        <!-- <mat-form-field appearance="fill" class="w-100 mb-2">
          <mat-label>Enter your old password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'"
          formControlName="oldPassword">
          <button mat-icon-button matSuffix (click)="hide = !hide"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field> -->

        <mat-form-field appearance="fill" class="w-100 mb-2">
          <mat-label>Enter your new password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'"
          formControlName="newPassword">
          <button mat-icon-button matSuffix (click)="hide = !hide"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-100 mb-2">
          <mat-label>Confirm your new password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'"
          formControlName="confirmNewPassword">
          <button mat-icon-button matSuffix (click)="hide = !hide"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <div>
          <button class="btn btn-sm save mr-2" type="submit">Submit</button>
          <button class="btn btn-sm cancel ml-2" type="button"
           *ngIf="role === 'MEMBER'"
          routerLink="/member/edit-profile"
          >Back</button>
          <button class="btn btn-sm cancel ml-2"
          *ngIf="role === 'TREASURER' || role === 'PRESIDENT' ||  role === 'SECRETARY'"
          type="button" routerLink="/admin/edit-profile">Back</button>
        </div>

      </form>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>

import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TestService } from "../test.service";
import { Membercards } from "../models/membercards";
import { AdminService } from "../services/admin.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { InactiveComponent } from "./inactive/inactive.component";

@Component({
  selector: "app-members",
  templateUrl: "./members.component.html",
  styleUrls: ["./members.component.scss"],
})
export class MembersComponent implements AfterViewInit {
  displayedColumns: string[] = [
    "memberID",
    "name",
    "status",
    "phoneNumber",
    "joinDate",
    "renewalDate",
    "action",
  ];
  dataSource: MatTableDataSource<Membercards>;
  membershipId: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private test: TestService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<Membercards>();
  }

  all: string;
  activeOnly: boolean;
  showAlertMessage: string;
  role: string;
  searchInput: string;
  applyFilter() {
    this.dataSource.filter = this.searchInput.trim().toLowerCase();
  }

  inactive(memberId: string) {
    this.membershipId = memberId;
    const dailogRef = this.dialog.open(InactiveComponent);
    dailogRef.afterClosed().subscribe((result) => {
      this.adminService
        .inactivateUserMembership(this.membershipId)
        .subscribe((result) => {
          if (result.approvalDone) {
            this.showAlertMessage = result.message;
          }
          console.log(this.showAlertMessage);
        });
    });
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    console.log(this.role);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

    this.membershipId = localStorage.getItem("memberid");

    this.all = this.route.snapshot.paramMap.get("all");
    if (this.all == "all") {
      this.adminService.getAllMembers().subscribe((data) => {
        this.dataSource.data = data;
        this.dataSource.data = this.dataSource.data.filter(
          (x) => x.memberID != this.membershipId
        );
      });
    } else {
      this.adminService.getActiveMembers().subscribe((data) => {
        this.dataSource.data = data;
        this.dataSource.data = this.dataSource.data.filter(
          (x) => x.memberID != this.membershipId
        );
      });
    }

    console.log(this.all);
  }
}

<form [formGroup]="formVariable" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="dialog">
      <h2 mat-dialog-title>Add Event:</h2>
  
      <mat-form-field appearance="fill" id="form">
        <input  id="eventname" required formControlName="eventName" matInput>
        <mat-label>Event Name</mat-label>
      </mat-form-field>
  
      <mat-form-field appearance="fill" id="form">
        <textarea id="eventdescription" formControlName="eventDescription" matInput></textarea>
        <mat-label>Event Description</mat-label>
      </mat-form-field>
  
      <div class="d-inline-flex  w-100">
        <mat-form-field appearance="fill">
          <mat-label>Event Date</mat-label>
          <input matInput [matDatepicker]="picker" required formControlName="eventDate" [min]="minDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="ml-4 w-100">
          <mat-label>Category</mat-label>
          <mat-select formControlName="eventCategory" required>
            <mat-option value="announcement">Announcement</mat-option>
            <mat-option value="event">Event</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </div>
  </mat-dialog-content>
  
  
  
  <mat-dialog-actions class="mb-2 buttons justify-content-center">
    <button mat-button mat-dialog-close type="submit"
    mat-raised-button color="accent"
    (click)="openSnackBar()">Submit</button>
    <button mat-button mat-dialog-close mat-raised-button
      color="accent" type="button">Close</button>
  </mat-dialog-actions>
  
</form>
<div class="container-fluid bg-light px-5 ml-0">
  <h3 class="pt-3 donation-info-padding">Donation <span class="info"> Information</span></h3>
  <hr class="horizontal-bar" />
  <div class="row">
    <div class="col-lg-5">
      <div class="form-group mt-4">
        <label for="selectCause">Select A Cause</label>
        <ng-select
          placeholder="Select A Cause"
          id="membership"
          [items]="cause"
          bindLabel="value"
          bindValue="name"
          [(ngModel)]="selectedValue"
        >
        </ng-select>
      </div>
      <div class="form-group mt-4">
        <label for="selectCause"
          >Donation Amount<span class="validate">*</span></label
        >
        <div class="input-group mb-3 input-group-lg">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Enter Amount"
            [disabled]="radioSelected != 0"
            [(ngModel)]="selectedAmount"
          />
        </div>
      </div>
      <div class="form-group mt-4">
        <label for="doantion"
          >Would you like to keep the donation Anonymous</label
        ><br />
        <label class="radio-inline mr-3" *ngFor="let item of anonymous">
          <input
            type="radio"
            name="radio"
            [(ngModel)]="isSelected"
            (ngModelChange)="onChange(item)"
            [value]="item.value"
          />
          {{ item.value }}
        </label>
      </div>
    </div>
    <div class="col-lg-5">
      <div class="form-group mt-4">
        <label for="donateFor">I Want To Donate for*</label>
        <ng-select
          placeholder="I Want To Donate for"
          id="membership"
          [items]="cause"
          bindLabel="value"
          bindValue="name"
          [(ngModel)]="selectedValue"
        >
        </ng-select>
      </div>
      <div class="form-group mt-4">
        <label for="doantion"> </label><br />
        <label class="radio-inline mr-3" *ngFor="let item of donation">
          <input
            type="radio"
            [(ngModel)]="radioSelected"
            [value]="item.value"
            (change)="onItemChange(item)"
            [checked]="radioSelected"
          />
          $ {{ item.name }}
        </label>
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>

  <mat-tab-group mat-align-tabs="start" class="tabs">
    <hr class="my-4" />

    <mat-tab label="PayLah">
      <div class="qrcode container-fluid text-center pt-5">
        <img [src]="'./assets/qrcode.JPG'" alt="" class="img-fluid"/>
      </div>

      <div class="py-3">
        <p style="font-size: 13px">
          <span class="vaildate">*</span> Please Share your PayLah Transaction
          ref no (Found in your Paylah History) against the above Payment
        </p>
      </div>

      <mat-form-field appearance="fill" class="mt-3 w-50">
        <mat-label>Transaction Reference Number</mat-label>
        <input
          matInput
          placeholder="TXN No. (Found in Paylah History)"
          [(ngModel)]="transactionRef"
          required
        />
      </mat-form-field>

      <div class="text-left mb-5">
        <button mat-raised-button class="next" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-tab>

    <!-- <mat-tab label="Paynow">
      <h3 class="pt-5">PayLah <span class="info"> Details</span></h3>
      <hr class="horizontal-bar2" />
      <div class="row">
        <div class="col-lg-5">
          <div class="form-group mt-4">
            <label for="cardHolderName"
              >Mobile Number:<span class="validate">*</span></label
            >
            <input
              class="form-control form-control-lg"
              type="text"
              placeholder="Enter 8 digits of SG Mobile Number"
            />
          </div>

          <p class="mt-4">
            By submitting this form I agree that I am above 18 years of age and
            accept the Terms & Condition of KCAS.
          </p>
          <button
            type="submit"
            class="btn mt-4 mb-4 btn-block btn-warning payBtn"
          >
            Request for Payment
          </button>
        </div>

        <div class="col-lg-1 verticalBar"></div>
        <div class="col-lg-6">
          <table>
            <tr>
              <th>Cause</th>
              <td>:</td>
              <td style="margin-left: 2rem">
                {{ selectedValue }}
              </td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>:</td>
              <td style="margin-left: 2rem; text-align: left">
                {{ selectedAmount | currency: "USD" }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</div>

import { TestService } from './../test.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AdminService } from '../services/admin.service';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../services/login-service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  email: any;
  memberShipId: any;
  hide: boolean;
  role: string;
  personalDetails: any;


  constructor(private fb: FormBuilder,
    private adminService: AdminService,
    private http: HttpClient,
    private loginService: LoginService,
    private snackBar: MatSnackBar) { }

  formVariable: FormGroup = this.fb.group(
    { newPassword:"", confirmNewPassword: ""});

  onSubmit() {
    if(this.formVariable.value.newPassword===this.formVariable.value.confirmNewPassword && 
      (this.formVariable.value.newPassword &&
        this.formVariable.value.confirmNewPassword !=="")){
        const data = {
          userId: this.memberShipId,
          password: this.formVariable.value.confirmNewPassword
        }
        this.loginService.changePassword(data).subscribe((postData) =>{
          console.log(postData);
        })
        { this.snackBar.open("Profile Password Updated Successfully", 
        this.memberShipId, {
          duration: 2000,
        });
      }
        console.log(data);
      }
    else
    alert('Passwords did not Match');
  }
  


  ngOnInit(): void {
    this.hide = true;
    this.role = localStorage.getItem("role");
    this.memberShipId = localStorage.getItem("memberid");
  }
}

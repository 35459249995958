<div
  class="navbar d-inline-flex bg-light float-md-right pl-5 pr-3 py-0"
  style="z-index: 900"
>
  <div class="d-flex w-75 menu-style" style="height: fit-content">
    <button
      type="button"
      class="mr-2 menubutton"
      (click)="toggleMenu = !toggleMenu"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <h4 class="username mb-0" style="color: #6d0005">
      Welcome {{ userName + " (" + role + ")" }}
    </h4>
  </div>

  <div class="w-25 rightmenu">
    <div class="d-inline-flex float-right w-75">
      <div class="mr-2 mt-2 m-auto">
        <a routerLink="/admin/messages">
          <!-- <i class="fa fa-inbox mr-2" matBadge="5"
          [matBadgeHidden]="hidden"  aria-hidden="true" ></i> -->
          <mat-icon
            class="mr-2 mt-2"
            [matBadge]="messagesCount"
            [matBadgeHidden]="hidden"
            matBadgeColor="warn"
            >notifications</mat-icon
          ></a
        >
        <a
          ><img
            class="mr-3 mb-2 ml-2 image"
            routerLink="/admin/profile"
            src="../../assets/user.png"
          />
          <mat-icon
            class="mt-2 pt-1"
            routerLink="/"
            style="color: #ffae13; cursor: pointer"
            matBadgeColor="warn"
            >login</mat-icon
          ></a
        >
      </div>
      <!-- <span class="mr-2 date">{{currentDate | date:'dd-MM-yyyy'}}</span> -->
    </div>
  </div>
</div>

<div class="sidebarmenu col-md-12" *ngIf="toggleMenu">
  <div class="d-inline-flex my-3 cursor-pointer" style="width: 100%">
    <img
      src="../../assets/kcas_logo.png"
      alt="Logo"
      style="height: 3rem; width: 3rem; margin-left: 0.5rem"
    />
    <h3 class="my-auto">KCAS</h3>
  </div>
  <ul class="mx-auto">
    <li class="my-2">
      <a
        routerLink="/admin"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        ><span
          ><i
            class="fa fa-cubes mx-2"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            aria-hidden="true"
          ></i></span
        >Dashboard
      </a>
    </li>
    <li class="my-2" *ngIf="role === 'TREASURER'">
      <a routerLink="/admin/payments" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-dollar mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Payments</a
      >
    </li>
    <li class="my-2">
      <a [routerLink]="['/admin/members', 'all']" routerLinkActive="active">
        <span
          ><i
            class="fa fa-users mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Members</a
      >
    </li>

    <li class="my-2">
      <a routerLink="/admin/events" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-coffee mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Events</a
      >
    </li>
    <li class="my-2">
      <a routerLink="/admin/reports" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-volume-up mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Reports</a
      >
    </li>
    <li class="my-2">
      <a routerLink="/admin/profile" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-smile-o mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Profile</a
      >
    </li>
    <li class="my-2">
      <a routerLink="/admin/donate" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-tint mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Donate</a
      >
    </li>
    <li class="my-2" *ngIf="role === 'PRESIDENT'">
      <a routerLink="/admin/admin-center" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-cog mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i
        ></span>
        Admin Center</a
      >
    </li>
    <li class="my-2" *ngIf="role === 'PRESIDENT'">
      <a routerLink="/admin/role-change" routerLinkActive="active"
        ><span
          ><i
            class="fa fa-users mx-2"
            routerLinkActive="active"
            aria-hidden="true"
          ></i></span
        >Change Roles</a
      >
    </li>
  </ul>
  <div class="w-25">
    <div class="d-inline-flex float-right w-75">
      <div class="mr-2 mt-2 m-auto">
        <a routerLink="/admin/messages">
          <!-- <i class="fa fa-inbox mr-2" matBadge="5"
          [matBadgeHidden]="hidden"  aria-hidden="true" ></i> -->
          <mat-icon
            class="mr-2 mt-2"
            [matBadge]="messagesCount"
            [matBadgeHidden]="hidden"
            matBadgeColor="warn"
            >notifications</mat-icon
          ></a
        >
        <a
          ><img
            class="mr-3 mb-2 ml-2 image"
            routerLink="/admin/profile"
            src="../../assets/user.png"
          />
          <mat-icon
            class="mt-2 pt-1"
            routerLink="/"
            style="color: #ffae13; cursor: pointer"
            matBadgeColor="warn"
            >login</mat-icon
          ></a
        >
      </div>
      <!-- <span class="mr-2 date">{{currentDate | date:'dd-MM-yyyy'}}</span> -->
    </div>
  </div>
</div>

<nav
  class="navbar navbar-expand-lg mx-auto mb-5"
  style="background-color: #8f1313"
>
  <div class="text-center">
    <a class="navbar-brand">
      <img src="./assets/kcas_logo.png" width="75px" height="75px" />
      <b class="mx-3 mt-2 text-white">KCAS</b></a
    >
  </div>
  <div>
    <a routerLink="/" style="color: #ffae13; font-weight: 700">SignIn</a>
  </div>
  <div class="w-75 text-center mr-5 text-div">
    <h3 class="text-white text-style">MEMBERSHIP APPLICATION FORM</h3>
  </div>
</nav>

<div class="container-fluid m-auto">
  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <form [formGroup]="firstFormGroup">
        <div class="m-auto w-50 pt-3">
          <div class="text-left">
            <h3>Membership <span class="info">Info</span></h3>
            <div class="line my-2"></div>
          </div>
        </div>

        <div class="text-center pt-3 w-50 m-auto">
          <p class="text-left mb-0">Thanks for your Interest.</p>
          <br />
          <p class="text-left mb-0" style="font-size: 0.8rem">
            <span class="required">*</span>
            <b>
              At KCAS, We'll accept Memberships only through Referrals. Please
              provide your referee's details to start Signing Up!</b
            >
          </p>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-hint
              ><p style="font-size: 0.8rem; color: #6d0005">
                *Please select the Membership type, You'll be asked to provide
                Information accordingly.
              </p></mat-hint
            >
            <mat-label
              >Membership Type<span class="required">*</span></mat-label
            >
            <mat-select formControlName="membershipIDType">
              <mat-option value="singleAnnual"
                >Single Annual
                <span class="pull-right info"><b>SGD 25</b></span></mat-option
              >
              <mat-option value="singleLife"
                >Single Life
                <span class="pull-right info"><b>SGD 150</b></span>
              </mat-option>
              <mat-option value="familyAnnual"
                >Family Annual
                <span class="pull-right info"><b>SGD 45</b></span>
              </mat-option>
              <mat-option value="familyLife"
                >Family Life
                <span class="pull-right info"><b>SGD 250</b></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-5 w-50">
            <mat-label>Referred By (Member One)</mat-label>
            <input matInput placeholder="Referee 1 Name" />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field
            hintLabel="Max 15 characters"
            appearance="fill"
            class="mt-3 w-50"
          >
            <mat-label>KCAS_XXXX</mat-label>
            <input
              matInput
              #input1
              maxlength="15"
              #memberid1
              formControlName="membershipOne"
              (blur)="validateId1($event)"
              placeholder="Referee 1 ID"
              required
            />
            <mat-hint align="end">{{ input1.value?.length || 0 }}/15</mat-hint>
          </mat-form-field>
          <div *ngIf="this.id1ValidateErrorMessage" class="required">
            {{ this.id1ValidateErrorMessage }}
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Referred By (Member Two)</mat-label>
            <input matInput placeholder="Referee 2 Name" />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field
            hintLabel="Max 15 characters"
            appearance="fill"
            class="mt-3 w-50"
          >
            <mat-label>KCAS_XXXX</mat-label>
            <input
              matInput
              #input2
              maxlength="15"
              formControlName="membershipTwo"
              (blur)="validateId2($event)"
              placeholder="Referee 2 ID"
              required
            />
            <mat-hint align="end">{{ input2.value?.length || 0 }}/15</mat-hint>
          </mat-form-field>
          <div *ngIf="this.id2ValidateErrorMessage" class="required mb-2">
            {{ this.id2ValidateErrorMessage }}
          </div>
        </div>

        <div class="text-center">
          <button
            mat-raised-button
            matStepperNext
            [disabled]="buttonDisabled1 || buttonDisabled2"
            class="next"
          >
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel></ng-template>

        <div class="m-auto w-50 pt-3">
          <div class="text-left">
            <h3>Personal <span class="info">Info</span></h3>
            <div class="line my-2"></div>
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Enter Your Name"
              formControlName="firstName"
              required
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Surname</mat-label>
            <input
              matInput
              placeholder="Enter Your Surname"
              formControlName="surName"
              required
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>Preferred Name</mat-label>
            <input
              matInput
              formControlName="preferredName"
              placeholder="Preferred Name"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="mt-3 w-25 ml-1">
            <mat-label>Gotram</mat-label>
            <input
              matInput
              formControlName="gotram"
              required
              placeholder="Enter Your Gotram"
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <div class="w-50 mx-auto d-inline-flex d0b-styles">
            <mat-form-field appearance="fill" class="mt-3 mr-1 w-50">
              <mat-label>Date of Birth</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="dateOfBirth"
                [max]="maxDate"
                required
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="mt-3 ml-1 w-50">
              <mat-label>Nationality</mat-label>
              <mat-select formControlName="nationality" required>
                <mat-option value="Indian">Indian</mat-option>
                <mat-option value="Singaporean">Singaporean</mat-option>
                <mat-option value="American">American</mat-option>
                <mat-option value="British">British</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 mr-1 w-25">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender" required>
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mt-3 ml-1 w-25">
            <mat-label>Blood Group</mat-label>
            <mat-select formControlName="bloodGroup" required>
              <mat-option value="A+">A+</mat-option>
              <mat-option value="A-">A-</mat-option>
              <mat-option value="B+">B+</mat-option>
              <mat-option value="B-">B-</mat-option>
              <mat-option value="O+">O+</mat-option>
              <mat-option value="O-">O-</mat-option>
              <mat-option value="AB+">AB+</mat-option>
              <mat-option value="AB-">AB-</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>NRIC/FIN</mat-label>
            <input
              matInput
              formControlName="fin"
              placeholder="Please enter last 4 digits of your NRIC/FIN"
              required
            />

            <mat-error
              *ngIf="
                secondFormGroupControls.fin?.errors?.maxlength ||
                secondFormGroupControls.fin?.errors?.minlength
              "
              >Please enter last 4 digits of your NRIC/FIN</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill" class="mt-3 w-25 ml-1">
            <mat-label>Native Place</mat-label>
            <input
              matInput
              placeholder="Enter Your Native Place"
              required
              formControlName="nativePlace"
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>Profession</mat-label>
            <input
              matInput
              placeholder="Enter Your Profession"
              formControlName="profession"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="mt-3 ml-1 w-25">
            <mat-label>Industry</mat-label>
            <mat-select formControlName="industry">
              <mat-option value="IT">IT</mat-option>
              <mat-option value="Business">Business</mat-option>
              <mat-option value="Education">Education</mat-option>
              <mat-option value="Logistics">Logistics</mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-100 justify-content-center d-inline-flex gap">
          <div class="w-25 relative-text-style">
            <p>Relatives Staying in Singapore?</p>
          </div>

          <div class="w-25 relative-style">
            <mat-radio-group
              formControlName="relativeStayInSG"
              aria-label="Select an option"
            >
              <mat-radio-button class="mr-3" value="Yes">Yes</mat-radio-button>
              <mat-radio-button value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div
          class="text-center"
          *ngIf="
            firstFormGroup.value.membershipIDType == 'singleAnnual' ||
            firstFormGroup.value.membershipIDType == 'singleLife'
          "
        >
          <button
            mat-raised-button
            class="mr-2"
            (click)="isEditable = !isEditable"
          >
            {{ !isEditable ? "Enable Edit Mode" : "Disable Edit Mode" }}
          </button>
          <button mat-raised-button matStepperPrevious class="next mx-2">
            Back
          </button>
          <button mat-raised-button matStepperNext class="next ml-2">
            Next
          </button>
        </div>
      </form>

      <form
        [formGroup]="spouseFormGroup"
        *ngIf="
          firstFormGroup.value.membershipIDType == 'familyAnnual' ||
          firstFormGroup.value.membershipIDType == 'familyLife'
        "
      >
        <ng-template matStepLabel>Spouse Info</ng-template>

        <div class="m-auto w-50">
          <div class="text-left">
            <h5>Spouse <span class="info">Info</span></h5>
            <div class="line my-2"></div>
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Spouse Name</mat-label>
            <input
              matInput
              placeholder="Spouse Name"
              formControlName="firstNameSpouse"
              required
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Spouse's Surname</mat-label>
            <input
              matInput
              placeholder="Spouse's Surname"
              formControlName="surnameSpouse"
              required
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <div class="w-50 mx-auto d-inline-flex">
            <mat-form-field appearance="fill" class="mt-3 mr-1 w-50">
              <mat-label>Date of Birth</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                formControlName="dateOfBirthSpouse"
                [max]="maxDate"
                required
              />
              <mat-datepicker-toggle matSuffix [for]="picker2">
              </mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="mt-3 ml-1 w-50">
              <mat-label>Nationality</mat-label>
              <mat-select formControlName="nationalitySpouse">
                <mat-option value="Indian">Indian</mat-option>
                <mat-option value="Singaporean">Singaporean</mat-option>
                <mat-option value="American">American</mat-option>
                <mat-option value="British">British</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 mr-1 w-25">
            <mat-label>NRIC/FIN (Spouse)</mat-label>
            <input
              matInput
              formControlName="finSpouse"
              placeholder="Enter Your NRIC/FIN Number"
              required
            />
          </mat-form-field>

          <mat-form-field appearance="fill" class="mt-3 ml-1 w-25">
            <mat-label>Blood Group (Spouse)</mat-label>
            <mat-select formControlName="bloodGroupSpouse" required>
              <mat-option value="A+">A+</mat-option>
              <mat-option value="A-">A-</mat-option>
              <mat-option value="B+">B+</mat-option>
              <mat-option value="B-">B-</mat-option>
              <mat-option value="O+">O+</mat-option>
              <mat-option value="O-">O-</mat-option>
              <mat-option value="AB+">AB+</mat-option>
              <mat-option value="AB-">AB-</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>Preferred Name</mat-label>
            <input
              matInput
              formControlName="preferredNameSpouse"
              placeholder="Enter Spouse's Preferred Name"
            />
          </mat-form-field>

          <mat-form-field
            hintLabel="8 Digits"
            appearance="fill"
            class="mt-3 w-25 ml-1"
          >
            <mat-label>SG Mobile Number</mat-label>
            <input
              matInput
              type="tel"
              #input5
              maxlength="8"
              formControlName="mobileNoSpouse"
              placeholder="Singapore Mobile Number of your Spouse"
            />
            <mat-hint align="end">{{ input5.value?.length || 0 }}/8</mat-hint>
          </mat-form-field>
          <div
            *ngIf="input5.value?.length > 0 && input5.value?.length < 8"
            class="required mb-2"
          >
            Enter Valid Mobile Number
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="emailSpouse"
              placeholder="Enter Email of Your Spouse"
            />
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>Spouse's Native Place</mat-label>
            <input
              matInput
              placeholder="Enter Native Place of Your Spouse"
              formControlName="nativePlaceSpouse"
            />
          </mat-form-field>

          <mat-form-field appearance="fill" class="mt-3 w-25 ml-1">
            <input
              matInput
              placeholder="India Address (Spouse)"
              formControlName="indiaAddressSpouse"
            />
            <mat-label>India Address</mat-label>
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-25 mr-1">
            <mat-label>Profession</mat-label>
            <input
              matInput
              placeholder="Spouse's Profession"
              formControlName="professionSpouse"
            />
          </mat-form-field>

          <mat-form-field appearance="fill" class="mt-3 ml-1 w-25">
            <mat-label>Industry</mat-label>
            <mat-select formControlName="industrySpouse">
              <mat-option value="IT">IT</mat-option>
              <mat-option value="Education">Education</mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--****** CHILD FORM ******-->

        <div class="text-center w-50 mx-auto" formArrayName="children">
          <mat-accordion
            multi
            [formGroupName]="i"
            *ngFor="let ar of children.controls; index as i"
          >
            <div class="text-right w-100 my-3">
              <button mat-raised-button color="warn" (click)="removeChild(i)">
                Remove Child
              </button>
            </div>

            <mat-expansion-panel class="mb-3">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>Child {{ i + 1 }} <span class="info">Details</span></b>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="text-center d-inline-flex w-100">
                <mat-form-field appearance="fill" class="mt-3 w-50 mr-1">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="Enter Child's Name"
                    formControlName="firstNameChild"
                    required
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" class="mt-3 w-50 ml-1">
                  <mat-label>Surname</mat-label>
                  <input
                    matInput
                    placeholder="Enter Surname"
                    formControlName="surnameChild"
                  />
                </mat-form-field>
              </div>

              <div class="text-center d-inline-flex w-100">
                <mat-form-field appearance="fill" class="mt-3 w-50 mr-1">
                  <mat-label>Gender</mat-label>
                  <mat-select formControlName="genderChild" required>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Others">Others</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mt-3 ml-1 w-50">
                  <mat-label>Blood Group</mat-label>
                  <mat-select formControlName="bloodGroupChild" required>
                    <mat-option value="A+">A+</mat-option>
                    <mat-option value="A-">A-</mat-option>
                    <mat-option value="B+">B+</mat-option>
                    <mat-option value="B-">B-</mat-option>
                    <mat-option value="O+">O+</mat-option>
                    <mat-option value="O-">O-</mat-option>
                    <mat-option value="AB+">AB+</mat-option>
                    <mat-option value="AB-">AB-</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="text-center d-inline-flex w-100">
                <mat-form-field appearance="fill" class="mt-3 w-50 mr-1">
                  <mat-label>Date of Birth</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker3"
                    [max]="minDate"
                    formControlName="dateOfBirthChild"
                    required
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker3">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mt-3 ml-1 w-50">
                  <mat-label>Nationality</mat-label>
                  <mat-select formControlName="nationalityChild" required>
                    <mat-option value="Indian">Indian</mat-option>
                    <mat-option value="Singaporean">Singaporean</mat-option>
                    <mat-option value="American">American</mat-option>
                    <mat-option value="British">British</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="text-center w-100 d-inline-flex">
                <mat-form-field appearance="fill" class="mt-3 w-50 mr-1">
                  <mat-label>Class</mat-label>
                  <input
                    matInput
                    placeholder="Class"
                    formControlName="studentClassChild"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" class="mt-3 w-50 ml-1">
                  <mat-label>NRIC/FIN</mat-label>
                  <input
                    matInput
                    placeholder="Enter Child's NRIC/FIN"
                    formControlName="finChild"
                  />
                </mat-form-field>
              </div>

              <div class="text-center w-100">
                <mat-form-field appearance="fill" class="mt-3 w-100">
                  <mat-label>School/College/University</mat-label>
                  <input
                    matInput
                    placeholder="Name of the Educational Institution"
                    formControlName="schoolChild"
                  />
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="text-right w-50 m-auto">
          <button mat-raised-button color="accent" (click)="createChild()">
            Add Child
          </button>
        </div>

        <!--****** CHILD FORM ENDS******-->

        <div class="text-center mt-3">
          <button
            mat-raised-button
            class="mr-2"
            (click)="isEditable = !isEditable"
          >
            {{ !isEditable ? "Enable Edit Mode" : "Disable Edit Mode" }}
          </button>
          <button mat-raised-button matStepperPrevious class="next mx-2">
            Back
          </button>
          <button mat-raised-button matStepperNext class="next ml-2">
            Next
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel></ng-template>

        <div class="m-auto w-50 pt-3">
          <div class="text-left">
            <h3>Contact <span class="info">Info</span></h3>
            <div class="line my-2"></div>
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              (blur)="validateEmail($event.value)"
              placeholder="Enter Your Valid Email"
            />
            <mat-hint
              ><span class="required">*</span> This Email is used for
              Communication throughout your Membership Period</mat-hint
            >
          </mat-form-field>
          <div *ngIf="this.emailIdValidation" class="required mb-2">
            {{ this.emailIdValidation }}
          </div>
        </div>

        <div class="text-center">
          <mat-form-field
            hintLabel="8 Digits"
            appearance="fill"
            class="mt-3 w-50"
          >
            <mat-label>SG Mobile Number</mat-label>
            <input
              matInput
              type="tel"
              #input3
              maxlength="8"
              formControlName="mobileNo"
              placeholder="Enter your Singapore Mobile Number"
              required
            />
            <mat-hint align="end">{{ input3.value?.length || 0 }}/8</mat-hint>
          </mat-form-field>
          <div
            *ngIf="input3.value?.length > 0 && input3.value?.length < 8"
            class="required mb-2"
          >
            Enter Valid Mobile Number
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <textarea matInput required formControlName="sgAddress"></textarea>
            <mat-label>Singapore Address</mat-label>
          </mat-form-field>
        </div>

        <div class="text-center">
          <mat-form-field
            hintLabel="10 Digits"
            appearance="fill"
            class="mt-3 w-50"
          >
            <mat-label>Emergency Contact</mat-label>
            <input
              matInput
              type="tel"
              #input4
              maxlength="10"
              formControlName="emergencyContactInIndia"
              placeholder="Enter Indian Mobile Number"
              required
            />
            <mat-hint align="end">{{ input4.value?.length || 0 }}/10</mat-hint>
          </mat-form-field>
          <p style="font-size: 0.8rem; color: #6d0005">
            <span class="required">*</span> Please provide valid India contact,
            So that we can contact in case of any Emergency.
          </p>
          <div
            *ngIf="input4.value?.length > 0 && input4.value?.length < 10"
            class="required mb-2"
          >
            Enter Valid Mobile Number
          </div>
        </div>

        <div class="text-center">
          <mat-form-field appearance="fill" class="mt-3 w-50">
            <textarea
              matInput
              required
              formControlName="indiaAddress"
            ></textarea>
            <mat-label>India Address</mat-label>
          </mat-form-field>
        </div>

        <div class="text-center">
          <button
            mat-raised-button
            class="mr-2"
            (click)="isEditable = !isEditable"
          >
            {{ !isEditable ? "Enable Edit Mode" : "Disable Edit Mode" }}
          </button>
          <button mat-raised-button matStepperPrevious class="next mx-2">
            Back
          </button>
          <button
            mat-raised-button
            matStepperNext
            [disabled]="buttonDisabled"
            class="next ml-2"
          >
            Next
          </button>
          <!-- [disabled]="buttonDisabled" -->
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="fourthFormGroup" [editable]="isEditable">
      <form [formGroup]="fourthFormGroup">
        <ng-template matStepLabel></ng-template>

        <div class="m-auto w-50 pt-3">
          <div class="text-left">
            <h3>General <span class="info">Info</span></h3>
            <div class="line my-2"></div>
          </div>
        </div>

        <div class="w-50 m-auto">
          <h5 class="mt-3">
            In which field you want to contribute to KCAS Community?
          </h5>
          <p style="font-size: 0.8rem; color: #6d0005">
            *You can select Single (or) Multiple (or) None.
          </p>

          <div class="d-flex flex-row">
            <div class="d-flex flex-column justify-content-center">
              <div class="mx-2">
                <mat-checkbox
                  [color]="color"
                  (change)="communityContribution('Career')"
                  ><p class="my-0">Career</p></mat-checkbox
                >
              </div>
              <div class="mx-2">
                <mat-checkbox
                  [color]="color"
                  (change)="communityContribution('Social Service')"
                  ><p class="my-0">Social Service</p></mat-checkbox
                >
              </div>
            </div>

            <div class="d-flex flex-column justify-content-center">
              <div class="mx-2">
                <mat-checkbox
                  [color]="color"
                  (change)="communityContribution('Woman Empowermen')"
                  ><p class="my-0">Woman Empowerment</p></mat-checkbox
                >
              </div>
              <div class="mx-2">
                <mat-checkbox
                  [color]="color"
                  (change)="communityContribution('Entrepreneur')"
                  ><p class="my-0">Entrepreneur</p></mat-checkbox
                >
              </div>
              <div class="mx-2"></div>
            </div>
          </div>
        </div>

        <div class="text-center mt-3">
          <p class="toggle pt-2 w-50 m-auto text-left general-info-style">
            Would You like to Volunteer KCAS Activities?
            <span class="info">(Optional)</span>
            <span class="ml-5">
              <mat-slide-toggle [color]="color" (change)="onChange1($event)">
              </mat-slide-toggle>
            </span>
          </p>

          <p class="toggle w-50 m-auto py-2 text-left general-info-style">
            Would You like to join KCAS Activities?
            <span class="info">(Optional)</span>
            <span style="margin-left: 6rem">
              <mat-slide-toggle
                value="warn"
                [color]="color"
                (change)="onChange2($event)"
              >
              </mat-slide-toggle>
            </span>
          </p>
        </div>

        <div class="m-auto w-50 pt-4">
          <div class="text-left">
            <h5><b>Consent</b></h5>
            <div
              class="line my-2"
              style="border-bottom: 2px solid #6d0005"
            ></div>
          </div>
        </div>

        <div class="d-flex flex-column">
          <div class="d-inline-flex m-auto w-50">
            <mat-checkbox
              class="mt-1 mr-2 mb-0"
              [color]="color"
              (change)="consentAgreement = !consentAgreement"
            >
              <p class="text-justify mb-0 consent-text-style">
                By Providing us your contact details, you agree to give your
                consent as per <b>PDPA</b> of Singapore.
                <span class="required">*</span>
              </p></mat-checkbox
            >
          </div>
          <div class="d-inline-flex m-auto w-50">
            <mat-checkbox
              class="mt-1 mr-2 mb-0"
              [color]="color"
              (change)="termsConditions = !termsConditions"
            >
              <p class="text-justify mb-0 consent-text-style">
                By clicking on Submit you agree to
                <b>Terms and Conditions</b> of <b>KCAS</b> Singapore.<span
                  class="required"
                  >*</span
                >
              </p></mat-checkbox
            >
          </div>
        </div>

        <div class="text-center mt-3">
          <button
            mat-raised-button
            class="mr-2"
            (click)="isEditable = !isEditable"
          >
            {{ !isEditable ? "Enable Edit Mode" : "Disable Edit Mode" }}
          </button>
          <button mat-raised-button matStepperPrevious class="next mx-2">
            Back
          </button>
          <button
            mat-raised-button
            matStepperNext
            (click)="onSubmit()"
            [disabled]="!consentAgreement || !termsConditions"
            class="ml-2"
          >
            Submit
          </button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>

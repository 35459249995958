
  <div class="row main-div">
    <div class="col-md-3 col-lg-3 col-sm-12 mb-3">
      <div class="card text-center">
        <div class="text-center d-inline">
          <img
            class="card-img mt-3"
            src="../../assets/user.png"
            alt="Card image"
            style="width: 40%"
          />
        </div>
        <div class="card-body text-center px-0">
          <h4 class="card-title">{{ userName }}</h4>
          <p>{{ role }}</p>
          <h5 class="py-1 text-success">{{ userStatus }}</h5>
          <p style="margin-top: 1rem">Update profile Photo</p>
          <hr />
          <div class="text-left card-details px-5">
            <i class="fa ml-1 fa-star" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              Member Since <br />
              <span class="date">{{ createdDate | date: "dd-MMM-yyyy" }}</span>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/member-profile">View Profile</a>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/dashboard">Payment History</a>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/messages">Notifications</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-lg-9 col-sm-12">
      <h3>Donation <span class="info"> Information</span></h3>
      <hr class="horizontal-bar" />
      <div class="row">
        <div class="col-lg-5">
          <div class="form-group mt-4">
            <label for="selectCause">Select A Cause</label>
            <ng-select
              placeholder="Select A Cause"
              id="membership"
              [items]="cause"
              bindLabel="value"
              bindValue="name"
              [(ngModel)]="selectedValue"
            >
            </ng-select>
          </div>
          <div class="form-group mt-4">
            <label for="selectCause"
              >Donation Amount<span class="validate">*</span></label
            >
            <div class="input-group mb-3 input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Enter Amount"
                [disabled]="radioSelected != 0"
                [(ngModel)]="selectedAmount"
              />
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="doantion"
              >Would you like to keep the donation Anonymous</label
            ><br />
            <label class="radio-inline mr-3" *ngFor="let item of anonymous">
              <input
                type="radio"
                name="radio"
                [(ngModel)]="isSelected"
                (ngModelChange)="onChange(item)"
                [value]="item.value"
              />
              {{ item.value }}
            </label>
          </div>
        </div>
        <div class="col-lg-5 col-md-5">
          <div class="form-group mt-4">
            <label for="donateFor">I Want To Donate for*</label>
            <ng-select
              placeholder="I Want To Donate for"
              id="membership"
              [items]="cause"
              bindLabel="value"
              bindValue="name"
              [(ngModel)]="selectedValue"
            >
            </ng-select>
          </div>
          <div class="form-group mt-4">
            <label for="doantion"> </label><br />
            <label class="radio-inline mr-3" *ngFor="let item of donation">
              <input
                type="radio"
                [(ngModel)]="radioSelected"
                [value]="item.value"
                (change)="onItemChange(item)"
                [checked]="radioSelected"
              />
              $ {{ item.name }}
            </label>
          </div>
        </div>
        <div class="col-lg-2"></div>
      </div>

      <mat-tab-group mat-align-tabs="start" class="tabs">
        <hr class="my-4" />

        <mat-tab label="Paynow" class="d-flex flex-row">
          <div class="qrcode container-fluid text-left pt-5">
            <img [src]="'./assets/qrcode.JPG'" class="text-left img-fluid" />
          </div>
          <div class="py-3">
            <p style="font-size: 13px">
              <span class="validate">*</span> Please Share your PayLah
              Transaction ref no (Found in your Paylah History) <br />
              against the above Payment
            </p>
          </div>

          <mat-form-field appearance="fill" class="mt-3 w-50">
            <mat-label>Transaction Reference Number</mat-label>
            <input
              matInput
              placeholder="TXN No. (Found in Paylah History)"
              [(ngModel)]="transactionRef"
              required
            />
          </mat-form-field>

          <div class="text-left mb-5">
            <button mat-raised-button class="next" (click)="onSubmit()">
              Submit
            </button>
          </div>
        </mat-tab>

        <!-- <mat-tab label="PayLah">
          <h3 class="pt-5">PayLah <span class="info"> Details</span></h3>
          <hr class="horizontal-bar2" />
          <div class="row">
            <div class="col-lg-5">
              <div class="form-group mt-4">
                <label for="cardHolderName"
                  >Mobile Number:<span class="validate">*</span></label
                >
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Enter 8 digits of SG Mobile Number"
                />
              </div>

              <p class="mt-4">
                By submitting this form I agree that I am above 18 years of age
                and accept the Terms & Condition of KCAS.
              </p>
              <button
                type="submit"
                class="btn mt-4 mb-4 btn-block btn-warning payBtn"
              >
                Request for Payment
              </button>
            </div>

            <div class="col-lg-1 verticalBar"></div>
            <div class="col-lg-6">
              <table>
                <tr>
                  <th>Cause</th>
                  <td>:</td>
                  <td style="margin-left: 2rem">
                    {{ selectedValue }}
                  </td>
                </tr>
                <tr>
                  <th>Amount</th>
                  <td>:</td>
                  <td style="margin-left: 2rem; text-align: left">
                    {{ selectedAmount | currency: "USD" }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>

<mat-dialog-content><div class="col-md-12 dialog">
  <h2 mat-dialog-title class="mb-1" 
  style="font-family:'Poppins', sans-serif ;">Description:</h2>
<mat-form-field appearance="fill" id="form">
  <mat-label >Reason for Approval</mat-label>
  <textarea #remarks id='textarea' matInput></textarea>
</mat-form-field></div>

<!-- <mat-form-field appearance="fill" id="form" class="w-100">
  <mat-label >Reason for Approval</mat-label>
  <input #remarks id='txnID' #transactionRefId matInput>
</mat-form-field> -->


<mat-dialog-actions class="mb-2 pl-3 buttons">
  <button mat-raised-button color="accent" (click)="submit(remarks.value)">Submit</button>
  <button mat-raised-button color="accent" mat-dialog-close>Close</button>
</mat-dialog-actions>

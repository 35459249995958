

<div class="my-5 container text-center my-5" >

  
  <h3><span style="color: #6D0005;">Thank you!!</span> for signing up in KCAS Community</h3>
  <p>We have sent an email with instructions  to follow  </p>
  <H3><a href="http://launchpad.ext.smactechlabs.net/KCASWordPress/">KCAS Home Page</a></H3>
</div>


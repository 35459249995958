import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login } from '../models/login';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../models/endpoints.enum';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  environment: any = environment;
  private loginAuthUrl = `${environment.api}${Endpoints.login}`;
  private loginForgotUrl = `${environment.api}${Endpoints.forgotPwd}`;
  private changePwd=`${environment.api}${Endpoints.changePwd}`;   // URL to web api

  constructor(
    private http: HttpClient
  ) { }

  loginAuth(login: Login): Observable<Login> {
    return this.http.post<Login>(this.loginAuthUrl, login, httpOptions);
  }

  public getPwd(login: Login): Observable<Login> {
    return this.http.post<Login>(this.loginForgotUrl, login, httpOptions);
  }

  getAutheToken(): string {
    return localStorage.getItem("jwtToken");
  }

  changePassword(status) {
    return this.http.post(this.changePwd, status,httpOptions);
  }

  displayTop() {
    return this.http.get("http://localhost:3000/admin", httpOptions);
  }
  displayUser() {
    return this.http.get("http://localhost:3000/member", httpOptions);
  }
  displayPaymentHistory() {
    return this.http.get("http://localhost:3000/payment-history", httpOptions);
  }
  getLoginDetail() {
    return this.http.get("http://localhost:3000/login", httpOptions);
  }
  getPersonalDetails() {
    return this.http.get("http://localhost:3000/person", httpOptions);
  }




}

<div class="container-fluid d-inline-flex p-0" style="min-height: calc(100%)">
  <div class="col-md-2 p-0 sidenavbar" >
    <app-side-nav-bar></app-side-nav-bar>
  </div>

  <div class="col-md-10 col-sm-12 d-flex flex-column p-0">
    <div style="height: 50px;">
      <app-top-nav-bar></app-top-nav-bar>
    </div>
    <div class="w-100 h-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { TestService } from './../test.service';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent implements OnInit {

  currentDate = new Date();
  messagesCount: any;
  toggleMenu: boolean = false;

  constructor(public test: TestService,
    private adminService: AdminService,) { }
  testdata: any;
  hidden = false;
  userName: string;
  role: string;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  ngOnInit(): void {
    this.userName = localStorage.getItem('name');
    this.role = localStorage.getItem('role');
    const membershipId = localStorage.getItem("memberid");
    this.adminService.getAllNotifications(membershipId).subscribe(result => {
      this.messagesCount = result.length;

    })
  }

}

<mat-dialog-content><div class="col-md-12 dialog">
  <h2 mat-dialog-title class="mb-1" 
  style="font-family:'Poppins', sans-serif ;">Description:</h2>
<mat-form-field appearance="fill" id="form">
  <mat-label >Reason for Member Deletion</mat-label>
  <textarea #remarks id='textarea'matInput></textarea>
</mat-form-field></div>


<mat-dialog-actions class="mb-2 pl-3 buttons">
  <button mat-button (click)="submit(remarks.value)">Submit</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>


<div class="container-fluid px-5" style="background-color: #e5e5e5"
  *ngIf="role!=='MEMBER'">
  <div>
    <h3 class="events pt-3 mb-2">
      Events
      <button class="btn btn-warning float-right" 
      *ngIf="role!=='MEMBER'" mat-raised-button
      (click)="openDialog()">
        Add Event
      </button>
    </h3>
  </div>

  <div class="row main">
    <div class="col-lg-6">
      <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-volume-up float-left my-1 mr-3"></i>Upcoming Events
        </div>
        <div class="card-body">
          <div class="row" *ngFor="let event of eventsdata; let i = index">
            <div class="col-lg-7" >
              <p>{{ event.eventName }}</p>
            </div>
            <div class="col-lg-5 mr-0 eventDate text-right" 
            >
              <p>{{ event.eventDate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-volume-up float-left my-1 mr-3"></i>Announcements
        </div>
        <div class="card-body">
          <div
            class="row"
            *ngFor="let announcement of announcementsdata; let i = index"
          >
            <div class="col-lg-7" >
              <p>{{ announcement.eventName }}</p>
            </div>
            <div class="col-lg-5 announceDate mr-0 text-right"
            >
              <p>{{ announcement.eventDate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid px-5"
*ngIf="role==='MEMBER'">

  <div class="row">

    <div class="col-md-3 col-lg-3">
      <div class="card">
        <div class="text-center d-inline">
          <img class="card-img mt-3" src="../../assets/user.png" 
          alt="Card image" style="width:40%;">
        </div>
        <div class="card-body text-center px-0">
          <h4 class="card-title">{{ userName }}</h4>
          <p>{{ role }}</p>
          <h5 class="py-1 text-success">{{userStatus}}</h5>
          <p style="margin-top: 1rem;">Update profile Photo</p>
          <hr />
          <div class="text-left card-details px-5 ">
            <i class="fa ml-1 fa-star" aria-hidden="true"></i>
            <p style="margin-left: 1rem">Member Since <br>
              <span class="date">{{createdDate| date:'dd-MMM-yyyy'}}</span></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/member-profile">View Profile</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/dashboard">Payment History</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/messages">Notifications</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-lg-9">
      <div class="">
        <h3 class="events mb-2">
          Events
        </h3>
      </div>
    
      <div class="row main">
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-header">
              <i class="fa fa-volume-up float-left my-1 mr-3"></i>Upcoming Events
            </div>
            <div class="card-body">
              <div class="row" *ngFor="let event of eventsdata; let i = index">
                <div class="col-lg-7" >
                  <p>{{ event.eventName }}</p>
                </div>
                <div class="col-lg-5 mr-0 eventDate text-right" 
                >
                  <p>{{ event.eventDate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-header">
              <i class="fa fa-volume-up float-left my-1 mr-3"></i>Announcements
            </div>
            <div class="card-body">
              <div
                class="row"
                *ngFor="let announcement of announcementsdata; let i = index"
              >
                <div class="col-lg-7" >
                  <p>{{ announcement.eventName }}</p>
                </div>
                <div class="col-lg-5 announceDate mr-0 text-right"
                >
                  <p>{{ announcement.eventDate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
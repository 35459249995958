<div class="container-fluid main-div" *ngIf="role==='MEMBER'">
  <div class="row">

    <div class="col-md-3 mb-3">
      <div class="card">
        <div class="text-center d-inline">
          <img class="card-img mt-3" src="../../assets/user.png"
          alt="Card image" style="width:40%;">
        </div>
        <div class="card-body text-center px-0">
          <h4 class="card-title">{{ userName }}</h4>
          <p>{{ role }}</p>
          <h5 class="py-1 text-success">{{userStatus}}</h5>
          <p style="margin-top: 1rem;">Update profile Photo</p>
          <hr />
          <div class="text-left card-details px-5 ">
            <i class="fa ml-1 fa-star" aria-hidden="true"></i>
            <p style="margin-left: 1rem">Member Since <br>
              <span class="date">{{createdDate| date:'dd-MMM-yyyy'}}</span></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/member-profile">View Profile</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/dashboard">Payment History</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/messages">Notifications</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5">
      <h3>Renew/ Upgrade <span class="info"> Payment</span></h3>
      <hr class="horizontal-bar" />
      <h4>Current Membership: <span class="info text-capitalize">
        {{currentMembershipType}}</span></h4>

        <div class="form-group mt-4 d-inline-flex">
          <label class="payment-styles" for="doantion"
            ><h4>Payment Towards</h4><span>:</span></label
          ><br/>
          <label class="payment-styles radio-inline mx-3 mt-1" *ngFor="let item of paymentFor">
            <input
              type="radio"
              name="radio"
              [(ngModel)]="isSelected"
              (ngModelChange)="onChange(item)"
              [value]="item.value"/>
            {{ item.name }}
          </label>
        </div>

      <div class="form-group mt-0" *ngIf="this.isSelected==='Upgradation'">
        <label for="payment-styles membershipType">Upgrade to:</label>
        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='singleAnnual'"
        [items]= "memberTypesForSingleAnnual" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='singleLife'"
        [items]= "memberTypesForSingleLife" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='familyAnnual'"
        [items]= "memberTypesForFamilyAnnual" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='familyLife'"
        [items]= "memberTypesForFamilyLife" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>
      </div>

      <div class="form-group mt-4">

        <mat-tab-group mat-align-tabs="start" class="tabs">
          <hr class="my-4" />
          <mat-tab label="Paynow">

            <div class="qrcode container-fluid text-center pt-5">
              <img [src]="'./assets/qrcode.JPG'"
              height="300" width="300">
            </div>
            <div class="py-3">
            <p style="font-size: 13px;">*Please Share your PayLah
              Transaction ref no (Found in your Paylah History)
              against the above Payment</p>
          </div>

          <mat-form-field appearance="fill" class="mt-3 w-100">
            <mat-label>Transaction Reference Number</mat-label>
            <input matInput placeholder="TXN No. (Found in Paylah History)"
            [(ngModel)]="transactionRef" required>
          </mat-form-field>

          <div class="text-left">
            <button mat-raised-button class="next"
            (click)="onSubmit()">Submit</button>
          </div>
            </mat-tab>

          <!-- <mat-tab label="PayLah">
            <h3 class="pt-5">PayLah <span class="info"> Details</span></h3>
            <hr class="horizontal-bar2" />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-4">
                  <label for="cardHolderName">
                    Mobile Number:<span class="validate">*</span></label>

                  <input
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Enter 8 digits of SG Mobile Number"
                  />
                </div>

                <p class="mt-4">
                  By submitting this form I agree that I am above 18 years of age and
                  accept the Terms & Condition of KCAS.
                </p>
                <button
                  type="submit"
                  class="btn w-100 mt-4 mb-4 btn-block btn-warning payBtn"
                >
                  Request for Payment
                </button>
              </div>
            </div>
          </mat-tab> -->

        </mat-tab-group>
      </div>

    </div>

    <div class="col-lg-1 verticalBar"></div>

    <div class="col-lg-3">
      <h6 class="pl-2"><b>Upgrade/ Renew <span class="info">to :</span></b></h6>
      <table>
        <tr>
          <th>Membership Type</th>
          <td>:</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='singleAnnual'">Single Annual</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='singleLife'">Single Life</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='familyAnnual'">Family Annual</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='familyLife'">Family Life</td>
        </tr>
        <tr>
          <th>From Date</th>
          <td>:</td>
          <td style="text-align: left;">{{currDate | date:'dd-MM-yyyy'}}</td>
        </tr>
        <tr *ngIf="currentMembershipType==='singleAnnual' ||
            currentMembershipType==='familyAnnual'">
          <th>Expiry Date</th>
          <td>:</td>
          <td style="text-align: left;">{{oneYear | date:'dd-MM-yyyy'}}</td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>:</td>
          <td [ngSwitch]="selectedValue" style="text-align: left;">
            <div  *ngSwitchCase="'singleAnnual'">$25.00</div>
            <div  *ngSwitchCase="'singleLife'">$150.00</div>
            <div  *ngSwitchCase="'familyAnnual'">$45.00</div>
            <div  *ngSwitchCase="'familyLife'">$250.00</div>
          </td>
        </tr>
        <tr><th></th><td></td><td><span style="font-size: 10px!important;color:gray">Including GST 7.0%</span></td></tr>
      </table>
    </div>

  </div>
</div>

<div class="container-fluid mt-5 main-div" *ngIf="role!=='MEMBER'">
  <div class="row">

    <!-- <div class="col-md-3">
      <div class="card">
        <div class="text-center d-inline">
          <img class="card-img mt-3" src="../../assets/user.png"
          alt="Card image" style="width:40%;">
        </div>
        <div class="card-body text-center px-0">
          <h4 class="card-title">{{ userName }}</h4>
          <p>{{ role }}</p>
          <h6 class="py-1">{{userStatus}}</h6>
          <p style="margin-top: 1rem;">Update profile Photo</p>
          <hr />
          <div class="text-left card-details px-5 ">
            <i class="fa ml-1 fa-star" aria-hidden="true"></i>
            <p style="margin-left: 1rem">Member Since <br>
              <span class="date">{{createdDate| date:'dd-MMM-yyyy'}}</span></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/member-profile">View Profile</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/dashboard">Payment History</a></p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/messages">Notifications</a></p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-md-6 col-lg-6">
      <h3>Renew/ Upgrade <span class="info"> Payment</span></h3>
      <hr class="horizontal-bar" />
      <h4>Current Membership: <span class="info text-capitalize">
        {{currentMembershipType}}</span></h4>

        <div class="form-group mt-4 d-inline-flex">
          <label class="payment-styles" for="doantion">
            <h4>Payment Towards:</h4></label><br/>
          <label  class="payment-styles radio-inline mx-3 mt-1" *ngFor="let item of paymentFor">
            <input
              type="radio"
              name="radio"
              [(ngModel)]="isSelected"
              (ngModelChange)="onChange(item)"
              [value]="item.value"/>
            {{ item.name }}
          </label>
        </div>

      <div class="form-group mt-0" *ngIf="this.isSelected==='Upgradation'">
        <label class="payment-styles" for="membershipType">Upgrade to:</label>
        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='singleAnnual'"
        [items]= "memberTypesForSingleAnnual" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='singleLife'"
        [items]= "memberTypesForSingleLife" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='familyAnnual'"
        [items]= "memberTypesForFamilyAnnual" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>

        <ng-select placeholder="Select A Membership Type" id="membership"
        *ngIf="this.currentMembershipType==='familyLife'"
        [items]= "memberTypesForFamilyLife" bindLabel="val" bindValue="name"
        [(ngModel)]="selectedValue">
        </ng-select>
      </div>

      <div class="form-group mt-4">

        <mat-tab-group mat-align-tabs="start" class="tabs">
          <hr class="my-4" />
          <mat-tab label="Paynow">

            <div class="qrcode container-fluid text-center pt-5">
              <img [src]="'./assets/qrcode.JPG'"
              height="300" width="300">
            </div>
            <div class="py-3">
            <p style="font-size: 13px;">*Please Share your PayLah
              Transaction ref no (Found in your Paylah History)
              against the above Payment</p>
          </div>

          <mat-form-field appearance="fill" class="mt-3 w-100">
            <mat-label>Transaction Reference Number</mat-label>
            <input matInput placeholder="TXN No. (Found in Paylah History)"
            [(ngModel)]="transactionRef" required>
          </mat-form-field>

          <div class="text-left">
            <button mat-raised-button class="next"
            (click)="onSubmit()">Submit</button>
          </div>
            </mat-tab>

          <!-- <mat-tab label="PayLah">
            <h3 class="pt-5">PayLah <span class="info"> Details</span></h3>
            <hr class="horizontal-bar2" />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-4">
                  <label for="cardHolderName"
                    >Mobile Number:<span class="validate">*</span></label
                  >
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Enter 8 digits of SG Mobile Number"
                  />
                </div>

                <p class="mt-4">
                  By submitting this form I agree that I am above 18 years of age and
                  accept the Terms & Condition of KCAS.
                </p>
                <button
                  type="submit"
                  class="btn w-100 mt-4 mb-4 btn-block btn-warning payBtn"
                >
                  Request for Payment
                </button>
              </div>
            </div>
          </mat-tab> -->

        </mat-tab-group>
      </div>

    </div>

    <div class="col-lg-1 col-md-1 verticalBar"></div>

    <div class="col-lg-5 col-md-4">
      <h6><b>Upgrade/ Renew <span class="info">to :</span></b></h6>
      <table>
        <tr>
          <th>Membership Type</th>
          <td>:</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='singleAnnual'">Single Annual</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='singleLife'">Single Life</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='familyAnnual'">Family Annual</td>
          <td style="margin-left: 1rem;text-align: left;"
          *ngIf="selectedValue=='familyLife'">Family Life</td>
        </tr>
        <tr>
          <th>From Date</th>
          <td>:</td>
          <td style="text-align: left;">{{currDate | date:'dd-MM-yyyy'}}</td>
        </tr>
        <tr *ngIf="currentMembershipType==='singleAnnual' ||
            currentMembershipType==='familyAnnual'">
          <th>Expiry Date</th>
          <td>:</td>
          <td style="text-align: left;">{{oneYear | date:'dd-MM-yyyy'}}</td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>:</td>
          <td [ngSwitch]="selectedValue" style="text-align: left;">
            <div  *ngSwitchCase="'singleAnnual'">$25.00</div>
            <div  *ngSwitchCase="'singleLife'">$150.00</div>
            <div  *ngSwitchCase="'familyAnnual'">$45.00</div>
            <div  *ngSwitchCase="'familyLife'">$250.00</div>
          </td>
        </tr>
        <tr><th></th><td></td><td><span style="font-size: 10px!important;color:gray">Including GST 7.0%</span></td></tr>
      </table>
    </div>

  </div>
</div>

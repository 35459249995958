<div class="container-fluid pt-5 px-5 w-50">
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input type="text"
            placeholder="Candidate"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | 
        async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div>
      <mat-form-field class="example-full-width">
        <input type="text"
              placeholder="Select Role"
              aria-label="Number"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async "[value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="w-100 mb-2">
        <mat-label>Description</mat-label>
        <input matInput #message
        placeholder="Details">
      </mat-form-field>
    </div>

    <div class="button-styles">
      <button class="btn btn-sm save mr-2">Save</button>
      <button class="btn btn-sm cancel ml-2">Cancel</button>
    </div>
  </form>
</div>

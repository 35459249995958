<div class="row px-3">
  <div class="col-md-3 col-sm-12 mb-4">
    <div class="card">
      <div class="text-center d-inline">
        <img class="card-img mt-3" src="../../assets/user.png"
        alt="Card image" style="width:40%;">
      </div>
      <div class="card-body text-center px-0">
        <h4 class="card-title">{{ userName }}</h4>
        <p>{{ role }}</p>
        <h5 class="py-1 text-success">{{userStatus}}</h5>
        <p style="margin-top: 1rem;">Update profile Photo</p>
        <hr />
        <div class="text-left card-details px-5 ">
          <i class="fa ml-1 fa-star" aria-hidden="true"></i>
          <p style="margin-left: 1rem">Member Since <br>
            <span class="date">{{createdOn| date:'dd-MMM-yyyy'}}</span></p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a routerLink="/member/member-profile" matTooltip="View Your Profile">View Profile</a></p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-arrow-up" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a routerLink="/member/renew-payment" matTooltip="Upgrade your Plan">Upgrade</a></p>
        </div>
        <div class="card-details px-5">
          <i class="fa ml-1 fa-bell" aria-hidden="true"></i>
          <p style="margin-left: 1rem">
            <a routerLink="/member/messages" matTooltip="Notifications">Notifications</a></p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 col-md-8 col-sm-12 ml-2">
    <h3>Payment <span class="info"> History</span></h3>
    <hr class="horizontal-bar" />
    <h5 class="mt-4 ml-0">Member ID: <span class="info"><b>{{membershipId}}</b></span></h5>
    <div class="table-responsive w-100">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Payment Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Membership Status</th>
            <th scope="col">Issue Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let paymentHistory of paymentHistoryDetails; ">
            <th style="padding-top: 0.5rem;">{{paymentHistory.id}}</th>
            <td>{{paymentHistory.purpose}}</td>
            <td>{{paymentHistory.amount | currency}}</td>
            <td>
              <ng-container *ngIf="paymentHistory?.paymentStatus== 'NOT-PAID';
              then danger ; else warnings">
              </ng-container>
              <ng-template #danger>
                <p class= "status mb-0 w-75 m-auto text-center"
                style="background-color: #6D0005;color: white;"
                >{{paymentHistory?.paymentStatus}}</p>
              </ng-template>
                <ng-template #warnings>
                  <p class= "status mb-0 w-75 m-auto text-center"
                  style="background-color:green;color: white;">
                    {{paymentHistory?.paymentStatus}}</p>
                </ng-template>
            </td>
            <td style="color: #2D9CDB;">{{paymentHistory.membershipStatus}}</td>
            <td>{{paymentHistory.issueDate | date: 'dd-MMM-yyyy'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<nav class="navbar navbar-expand-lg mx-auto" 
style="background-color: #8f1313;">
<div class="text-center d-flex flex-row w-100 nav-style">
<div>  <a class="navbar-brand">
  <img src="./assets/kcas_logo.png" 
  width="75px" height="75px"/> 
  <b class="mx-3 mt-2">KCAS</b></a></div>
    <div class="text-white m-auto text-div">
      <h3 class="text-style">Forgot Password</h3></div>
</div>
</nav>

<div class="form-style">
  <form class="card mt-4 col-md-4 mx-auto p-0">
    <mat-card class="card-body">
  
      <mat-form-field class="w-100"
        appearance="fill">
        <mat-label for="email">Email</mat-label>
        <input [(ngModel)] = "login.emailId"
          matInput 
          type="email" id="email"
          name="emailId"placeholder="Enter your registered email"
          required/>
      </mat-form-field>
  
      <button mat-raised-button color="primary"
      (click)="invokeForgotPwdService()"
      class="btn btn-warning" 
      type="submit">Get New Password
    </button>
    
    <button mat-raised-button color="accent"
      routerLink = "/"
      class="btn btn-danger"
      type="submit"
      style="float: right;">
      Back to Login
    </button>
  
    </mat-card>
  
    <span *ngIf="showMessage" angular-textcolor="">
      {{showMessage}}
    </span>
      
  
    <!-- <mat-card class="card-footer">
      <button mat-raised-button color="primary"
        (click)="invokeForgotPwdService()"
        class="btn btn-warning" 
        type="submit">Get New Password
      </button>
      
      <button mat-raised-button color="accent"
        routerLink = "/"
        class="btn btn-danger"
        type="submit"
        style="float: right;">
        Back to Login
      </button>
  
    </mat-card> -->
  
  </form>
</div>

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Endpoints } from "../models/endpoints.enum";
import { Observable } from "rxjs";
import { Membercards } from "../models/membercards";
import { ApproveInfo } from "../models/approve-info";
import { Payment } from '../models/payment';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AdminService {
  environment: any = environment;
  private getMemberCardsUrl = `${environment.api}${Endpoints.membersInCards}`;
  private approveMembershipUrl = `${environment.api}${Endpoints.approveMembership}`;
  private getActiveMembersUrl = `${environment.api}${Endpoints.getActiveMembers}`;
  private getFundsUrl = `${environment.api}${Endpoints.getFunds}`;
  private getFundsForTodayUrl = `${environment.api}${Endpoints.getFundsForToday}`;
  private getProfileurl = `${environment.api}${Endpoints.getProfile}`;
  private getAllFundsUrl = `${environment.api}${Endpoints.getAllFunds}`;
  private getAllMembersUrl = `${environment.api}${Endpoints.getAllMembers}`;
  private inactivateMembershipUrl = `${environment.api}${Endpoints.inactivateMembership}`;
  private getAllGatheredFundsWithPaymentInfo = `${environment.api}${Endpoints.getAllGatheredFundsWithPaymentInfo}`;
  private getNotificationsUrl = `${environment.api}${Endpoints.getNotifications}`;
  private markAllAsReadUrl = `${environment.api}${Endpoints.markAllAsRead}`
  private markAsReadUrl = `${environment.api}${Endpoints.markAsRead}`

  constructor(private httpClient: HttpClient) { }

  approvalInfo = new ApproveInfo();

  // This methos to retrive data from service to display in Cards
  getMembersForCards(
    membershipId: string,
    role: string
  ): Observable<Membercards[]> {
    const getPendingMemUrl =
      `${this.getMemberCardsUrl}/` + membershipId + "/" + role;
    return this.httpClient.get<Membercards[]>(getPendingMemUrl, httpOptions);
  }

  inactivateUserMembership(membershipId: string): Observable<any> {
    const inactiveUrl = `${this.inactivateMembershipUrl}/` + membershipId;
    return this.httpClient.delete<any>(inactiveUrl, httpOptions);
  }

  markAsRead(id: any) {
    return this.httpClient.post<any>(`${this.markAsReadUrl}/${id}`, {}, httpOptions);

  }

  markAllAsRead(membershipId: any) {
    return this.httpClient.post<any>(`${this.markAllAsReadUrl}/${membershipId}`, {}, httpOptions);
  }

  getAllNotifications(role: string): Observable<any> {
    const notificationUrl = `${this.getNotificationsUrl}/` + role;
    return this.httpClient.get<any>(notificationUrl, httpOptions);
  }

  approveMembership(
    approvalId: number,
    action: string,
    reason: string,
    membershipId: string,
    approver: string,
    apprLevel: string,
    email: string
  ): Observable<any> {
    this.approvalInfo.action = action;
    this.approvalInfo.approvalId = approvalId;
    this.approvalInfo.approver = approver;
    this.approvalInfo.membershipId = membershipId;
    this.approvalInfo.reason = reason;
    this.approvalInfo.userApprovalLevel = apprLevel;
    this.approvalInfo.email = email;
    console.log(this.approveMembershipUrl);

    return this.httpClient.put(
      this.approveMembershipUrl,
      this.approvalInfo,
      httpOptions
    );
  }

  getActiveMembers(): Observable<Membercards[]> {
    return this.httpClient.get<Membercards[]>(
      this.getActiveMembersUrl,
      httpOptions
    );
  }

  getAllMembers(): Observable<Membercards[]> {
    return this.httpClient.get<Membercards[]>(
      this.getAllMembersUrl,
      httpOptions
    );
  }

  getAllPaymentsInfo(): Observable<Payment[]> {
    const urlToInvoke = `${this.getAllGatheredFundsWithPaymentInfo}/` + 'ALL';
    return this.httpClient.get<Payment[]>(
      urlToInvoke,
      httpOptions
    );
  }



  getFunds(): Observable<any> {
    return this.httpClient.get<any>(this.getFundsUrl, httpOptions);
  }

  getFundsForToday(): Observable<any> {
    return this.httpClient.get<any>(this.getFundsForTodayUrl, httpOptions);
  }

  getAllFunds(): Observable<any> {
    return this.httpClient.get<any>(this.getAllFundsUrl, httpOptions);
  }

  getMemberProfile(memberShipId: string): Observable<any> {
    const urlToInvoke = `${this.getProfileurl}/` + memberShipId;
    return this.httpClient.get<any>(urlToInvoke, httpOptions);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/admin-dashboard/dialog/dialog.component';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.scss']
})
export class InactiveComponent implements OnInit {

  constructor(public dailogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit(): void {
  }

  submit(remarks: string) : void {
    this.dailogRef.close(remarks);
  }

}

export class Login {

  userId: string ;
  emailId: string ;
  password: string ;
	role: string ;
	loginSuccess: boolean ;
	isUserActivated: boolean ;
	isUserExist:  boolean ;
	isPwdUpdateDone: boolean ;
  isEmailSent: boolean ;
  fullName: string;
  createdOn: string;
  jwtToken: string;
  

}

import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { TestService } from './../test.service';
import { AddEventDialogComponent } from './add-event-dialog/add-event-dialog.component';
import { EventsService } from '../services/events.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  memberShipId: string;

  constructor(public test: TestService, 
    private eventService: EventsService,
    private adminService: AdminService,
    private dialog: MatDialog) { }

  eventsdata: any;
  announcementsdata: any;
  currentDate = new Date();

  userName:string;
  role: string;
  createdDate: string;
  userStatus: string;
  

  openDialog() {
    this.dialog.open(AddEventDialogComponent);
  }


  ngOnInit(): void {

    this.userName = localStorage.getItem("name");
    this.role = localStorage.getItem("role");
    this.createdDate = localStorage.getItem("createdon");
    this.memberShipId = localStorage.getItem("memberid");

    this.adminService.getMemberProfile(this.memberShipId).subscribe((result) => {
      this.userStatus = result['memberStatus'];

    this.eventService.getEventData().subscribe((data:any) => {
      this.role =  localStorage.getItem("role");
      // this.eventsdata = data.filter(i => i.eventCategory === 'event');
      this.eventsdata = data['event'];
      this.announcementsdata = data['announcement'];
      // this.announcementsdata = data.filter(i => i.eventCategory === 'announcement');
      })
    });

  }
}

import { TestService } from './../test.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../services/admin.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Editprofile } from '../models/editprofile';
import { MemberServiceService } from '../services/member-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  constructor(private adminService: AdminService,
    private memberService: MemberServiceService,
    private fb: FormBuilder,
    private test: TestService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute) { }

  disableSelect = new FormControl(false);
  memberShipId: string;
  personalDetails: any;
  createdOn: string;
  email: string;
  role: string;
  showMessage: string;
  isChangePasswordShown: boolean;


  formVariable: FormGroup = this.fb.group(
    {
      nationality: "", mobileNo: "", 
      emergencyContactInIndia: "",
      sgAddress: "", profession: ""
    })

  onSubmit() {
    var data = this.formVariable.value;
    console.log(data);
    const editProfile = new Editprofile(this.memberShipId, data.nationality, data.mobileNo, data.emergencyContactInIndia,
      data.sgAddress, data.profession)
    this.memberService.editMemberProfile(editProfile).subscribe((postData) => {
      console.log(postData);
      if (postData) {
        console.log(this.role);
        if (this.role == 'MEMBER') {
          this.showMessage = "Member details are successfully updated..!";
          this.router.navigate(['/member/member-profile']);
        } else {
          this.router.navigate(['/admin/profile']);
        }
      } else {
        this.showMessage = "Member details are failed to update..please try again!";
      }
    })
  }

  openSnackBar() { this.snackBar.open("Profile Updated Successfully", 
    this.memberShipId, {
      duration: 2000,
    });
  }

  ngOnInit(): void {
    this.memberShipId = this.route.snapshot.paramMap.get("memberShipId");
    if (this.memberShipId == null) {
      this.memberShipId = localStorage.getItem("memberid");
    }

    if (this.memberShipId == localStorage.getItem("memberid")) {
      this.isChangePasswordShown = true;
    }
    this.createdOn = localStorage.getItem("createdon");
    this.adminService.getMemberProfile(this.memberShipId).subscribe((result) => {
      this.personalDetails = result;
      this.formVariable.patchValue(result);
      console.log(this.personalDetails);
      this.role = localStorage.getItem("role");
      console.log(this.role);
    });
  }
}



import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileComponent implements OnInit {
  userName: string;
  role: string;

  constructor(private adminService: AdminService) {}

  memberShipId: string;
  personalDetails: any;
  createdOn: string;
  email: string;


  ngOnInit(): void {
    this.userName = localStorage.getItem("name");
    this.role = localStorage.getItem("role");
    this.createdOn = localStorage.getItem("createdon");
    this.memberShipId = localStorage.getItem("memberid");
    this.adminService.getMemberProfile(this.memberShipId).subscribe((result) => {
      this.personalDetails = result;
      ;
      console.log(this.personalDetails);
    });

  }

}




import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from 'src/app/services/events.service';



@Component({
  selector: 'app-add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.scss']
})
export class AddEventDialogComponent implements OnInit {

  minDate = new Date();
  eventName: string;
  eventDescription: string;
  eventCategory: string;
  eventDate: string;
  formVariable: FormGroup=this.fb.group(
    {eventName:"", eventDescription:"", eventCategory:"", eventDate:""})
  

  constructor( private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private eventService: EventsService) { }

    openSnackBar() { this.snackBar.open("Event Added Successfully",
    this.formVariable.value.eventName,
    {
      duration: 2000,
    });
  }

  onSubmit(){
    var data = this.formVariable.value;
    data.eventDate = moment(data.eventDate).format('ddd DD MMM yyyy');
    this.eventService.postEventData(data).subscribe((postData) =>{
    })
  }


  ngOnInit(): void {
  }

}

<div class="container-fluid px-0" style="height: 100vh;">
  <img class="bg-img" [src]="'./assets/kcas_bg.png'">
  <div id="form" class="my-auto d-flex justify-content-center align-items-center h-100">



    <form (ngSubmit)="singInWithEmailAndPwd()" id="container"
      class="col-md-4 col-sm-6 col-lg-4 mx-auto my-auto"
      style="height: fit-content">
      
      <div class="text-center">


        <!-- <div class="pl-0 signin"> -->
          <h5 class="text-center mb-0" 
          style="font-weight: 550; padding-top: 9.5rem;">SIGN IN</h5>
        <!-- </div> -->

        <h6><span *ngIf="loginMessage" style="color: red">
            {{loginMessage}}
          </span> </h6>

        <span *ngIf="showErrorMessage" style="color: red">
          {{showErrorMessage}}
        </span>

        <mat-form-field class="form-group mb-0 w-75" appearance="fill">

          <mat-label>Email address:</mat-label>
          <input matInput [(ngModel)]="login.emailId" 
            type="email" class="w-100" 
            id="email" name="emailId"
            placeholder="Enter your email" required=true #emailId="ngModel" />

          <span class="text-danger" 
            *ngIf="(emailId.touched) && emailId.errors?.required">
          </span>

        </mat-form-field>

        <div  class="text-right w-75 m-auto">
          <a routerLink="/forgot-password" class="forgot">
            Forgot Password?</a>
        </div>

        <mat-form-field appearance="fill" class="form-group mb-0 w-75">

          <!-- Introduced for Angular Material<div class="input-group mb-3">
            <input #x matInput type="password" [(ngModel)]="login.password" 
              class="form-control" id="password" name="password"
              placeholder="Enter your password" #password="ngModel" /> -->

            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'"
            [(ngModel)]="login.password" #x id="password" name="password"
            required
            #password="ngModel" class="w-100" >

            <button mat-icon-button matSuffix type="button" (click)="hide = !hide" 
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>

            <span class="text-danger" 
            *ngIf="(password.touched) && password.errors?.required">
            </span>

            <!-- <div class="input-group-append">
              <span class="input-group-text" (click)="x.type=x.type=='password'?'text':'password'">
                <i class="fa fa-eye" [ngClass]="(x.type=='password')?' fa-eye':' fa-eye-slash'" aria-hidden="true"></i>
              </span>
            </div> -->
          <!-- </div> -->
        </mat-form-field>

        <!-- <div class="custom-control custom-checkbox my-1 mr-sm-2 text-left ml-5">
          <input type="checkbox" class="custom-control-input" id="customControlInline" style="background: ffae00" />
          <label class="custom-control-label" for="customControlInline">Remember me</label>
        </div> -->

        <div class="w-75 m-auto">
          <button mat-raised-button type="submit" 
          class="btn btn-warning login w-100 text-center
          px-5">

          <span *ngIf="!loading">SignIn</span>
          <span>
            <mat-spinner mode="indeterminate" value="100" 
            [diameter]="25" class="m-auto" *ngIf="loading"></mat-spinner>
          </span>
        </button>
        </div>

        <div class="form-group signup mt-1 ml-3">
          <a routerLink='/signup' class="mt-2">Not a Member? Sign-Up here!</a>
        </div>

      </div>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../models/endpoints.enum';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DonateService {

  environment: any = environment;
  private donateUrl = `${environment.api}${Endpoints.donate}`;

  constructor(private httpClient: HttpClient) { 
    
  }

  postDonationData(status: any) {
    return this.httpClient.post(this.donateUrl, status)
  }

}

import { DialogComponent } from "./dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TestService } from "./../test.service";
import { Component, OnInit } from "@angular/core";
import { AfterViewInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Membercards } from "../models/membercards";
import { AdminService } from "../services/admin.service";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "date",
    "name",
    "location",
    "phoneNumber",
    "mail",
    "status",
  ];
  dataSource: MatTableDataSource<Membercards>;

  searchInput: string;
  testdata: any;
  eventsdata: any;
  announcementsdata: any;
  requestsdata: any;
  activemembers: number;
  pendingrequests: number;
  totalGatheredFunds: number;
  fundsReceivedForDay: number;
  approvalId: number;
  membershipId: string;
  role: string;
  approver: string;
  message: string;
  approvalLevel: string;
  email: string;
  showAlertMessage: string;
  showSuccessMessage: string;
  showNoRecordsForApprove: string;
  receivedFunds: number;
  pendingFunds: number;
  date = new Date();
  transactionID: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  

  constructor(
    private test: TestService,
    private adminService: AdminService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<Membercards>();
  }

  accept(
    approvalId: number,
    membershipId: string,
    approvalLevel: string,
    email: string
  ) {
    this.approvalId = approvalId;
    this.membershipId = membershipId;
    this.approvalLevel = approvalLevel;
    this.email = email;
    this.role = localStorage.getItem("role");
    this.approver = localStorage.getItem("memberid");
    console.log(this.approvalLevel);
    const dailogRef = this.dialog.open(DialogComponent);

    dailogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.adminService
          .approveMembership(
            this.approvalId,
            "approve",
            result,
            this.membershipId,
            this.approver,
            this.approvalLevel,
            this.email
          )
          .subscribe((result) => {
            if (result.approvalDone) {
              this.dataSource.data = this.dataSource.data.filter(
                (x) => x.approvalId != this.approvalId
              );
              this.pendingrequests = this.dataSource.data.length;
              this.showSuccessMessage = result.message;
            } else {
              this.showAlertMessage = result.message;
            }
            console.log(this.showAlertMessage);
          });
        console.log(result);
      }
    });

    if (this.approvalLevel == "LEVEL2_APPROVAL") {
      this.adminService.getActiveMembers().subscribe((data) => {
        this.activemembers = data.length;
      });

      this.adminService.getFunds().subscribe((result) => {
        console.log(result);
        this.totalGatheredFunds = result;
      });
      // This retrives for today's received funds
      this.adminService.getFundsForToday().subscribe((result1) => {
        console.log(result1);
        this.fundsReceivedForDay = result1;
      });
    }
  }

  showProfile(membershipId: string): void {
    this.router.navigate(["/admin/profile", membershipId]);
  }

  reject(
    approvalId: number,
    membershipId: string,
    approvalLevel: string,
    email: string
  ) {
    this.approvalId = approvalId;
    this.membershipId = membershipId;
    this.approvalLevel = approvalLevel;
    this.email = email;
    this.role = localStorage.getItem("role");
    this.approver = localStorage.getItem("memberid");
    console.log(this.approvalLevel);
    const dailogRef = this.dialog.open(DialogComponent);
    dailogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.adminService
          .approveMembership(
            this.approvalId,
            "reject",
            result,
            this.membershipId,
            this.approver,
            this.approvalLevel,
            this.email
          )
          .subscribe((result) => {
            if (result.approvalDone) {
              this.dataSource.data = this.dataSource.data.filter(
                (x) => x.approvalId != this.approvalId
              );
              this.pendingrequests = this.dataSource.data.length;
              this.showSuccessMessage = result.message;
            } else {
              this.showAlertMessage = result.message;
            }
            console.log(this.showAlertMessage);
          });
        console.log(result);
      }
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  applyFilter() {
    this.dataSource.filter = this.searchInput.trim().toLowerCase();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.test.getAdminData().subscribe((data) =>{
    //   this.dataSource.data = data['requests'];
    // });
  }

  ngOnInit(): void {
    this.test.getEventData().subscribe((data: any) => {
      // console.log(data);
      // this.eventsdata = data.filter((i) => i.eventCategory === "event");
      // this.announcementsdata = data.filter(
      //   (i) => i.eventCategory === "announcement"
      this.eventsdata = data.event.slice(0,5);
      // this.eventsdata.length = 5;
      console.log(this.eventsdata);
      this.announcementsdata = data.announcement.slice(0,5);
      // this.announcementsdata.length = 5;
      console.log(this.announcementsdata);
    });

    this.adminService.getActiveMembers().subscribe((data) => {
      this.activemembers = data.length;
    });

    this.adminService.getAllFunds().subscribe((result) => {
      this.totalGatheredFunds = result.totalGatheredFunds;
      this.pendingFunds = result.pendingFunds;
      this.receivedFunds = result.receivedFunds;
    });

    // This retrives for today's received funds
    this.adminService.getFundsForToday().subscribe((result1) => {
      this.fundsReceivedForDay = result1;
    });

    this.membershipId = localStorage.getItem("memberid");
    this.role = localStorage.getItem("role");
    this.adminService
      .getMembersForCards(this.membershipId, this.role)
      .subscribe((data) => {
        console.log(data);
        this.dataSource.data = data
          .filter((x) => x.userStatus != "ACTIVE")
          .sort((a,b) => moment(a.joinDate).unix() - moment(b.joinDate).unix())
          .reverse();
          console.log(this.dataSource.data);
        this.pendingrequests = this.dataSource.data.length;
        if (this.pendingrequests == 0) {
          this.showNoRecordsForApprove =
            "There are no pending Membership Requests";
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { AdminInboxService } from '../services/admin-inbox.service';
import { AdminService } from '../services/admin.service';
import { MemberServiceService } from '../services/member-service.service';

@Component({
  selector: 'app-member-messages',
  templateUrl: './member-messages.component.html',
  styleUrls: ['./member-messages.component.scss']
})
export class MemberMessagesComponent implements OnInit {

  constructor(private adminService: AdminService,
    private alertService: AdminInboxService) { }

  testdata: any;
  messages: any;
  role: string;
  alertCount: number;
  userName: string;
  profession: string;
  membershipId: string;
  email: string;
  createdOn: string;
  userStatus: string;

  ngOnInit(): void {
    this.userName = localStorage.getItem("name");
    this.profession = localStorage.getItem("role");
    this.membershipId = localStorage.getItem("memberid");
    this.email = localStorage.getItem("email");
    this.createdOn = localStorage.getItem("createdon");
    if (this.createdOn != null) {
      this.userStatus = "ACTIVE";
    }
    this.role = localStorage.getItem("role");
    this.adminService.getAllNotifications(this.membershipId).subscribe(result => {
      this.messages = result;
      this.alertService.alertCount = this.messages.length;
    })
  }

  read(id: any) {
    this.adminService.markAsRead(id).subscribe(
      (res: any) => {
        this.ngOnInit();
      }, (err: any) => {
      }
    );
  }

  markAllAsRead() {
    this.adminService.markAllAsRead(this.membershipId).subscribe(
      (res: any) => {
        this.ngOnInit();
      }
    );
  }

  // ngOnInit(): void {


  //   // this.testService.getMsgDetails().subscribe( (details) =>{
  //   //     this.msgData = details;
  //   //     //console.log(this.msgData);
  //   //     this.activatedRoute.paramMap.subscribe(routes=>{
  //   //     // console.log(routes);
  //   //       this.message = this.msgData.find(
  //   //         data =>data.id==routes.get('id')
  //   //         );
  //   //     });
  //   // });
  // }

}

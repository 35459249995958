<div class="container px-5">
  <div class="row">
    <div class="col-md-6">
      <h3 class="mt-4">Approve <span class="info"> Payment</span></h3>
      <hr class="horizontal-bar" />
    </div>
    <div class="col-md-6"></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="payment-form pt-0 d-flex flex-column">
    <mat-form-field appearance="fill" class="mt-3">
      <mat-label>Membership ID</mat-label>
      <input matInput (blur)="validateId()" formControlName="membershipID" placeholder="Membership ID">
    </mat-form-field>
    <div class="d-inline-flex">
      <mat-form-field appearance="fill" class="mt-3 w-50 mr-2">
        <mat-label>Member Name</mat-label>
        <input matInput formControlName="name"
        placeholder="Member Name" disabled>
      </mat-form-field>
      <mat-form-field appearance="fill" class="mt-3 w-50">
        <mat-label> Current Membership</mat-label>
        <input matInput formControlName="membershipType"
        placeholder="Membership Type" disabled>
      </mat-form-field>
    </div>
    <span class="error" *ngIf="valid === 'false'">Member Not Found</span>
    <mat-form-field appearance="fill" class="mt-3">
      <mat-label>Purpose</mat-label>
      <mat-select formControlName="purpose">
        <mat-option value="Membership">Membership</mat-option>
        <mat-option value="Renewal">Renewal</mat-option>
        <mat-option value="Upgradation">Upgradation</mat-option>
        <mat-option value="Donation">Donation</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mt-3" *ngIf="form.value.purpose === 'Upgradation'">
      <mat-label>Membership Type</mat-label>
      <mat-select formControlName="membershipType">
        <mat-option value="singleAnnual">Single Annual</mat-option>
        <mat-option value="singleLife">Single Life</mat-option>
        <mat-option value="familyAnnual">Family Annual</mat-option>
        <mat-option value="famiyLife">Family Life</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mt-3">
      <mat-label>Payment Reference ID</mat-label>
      <input matInput formControlName="paymentReferenceId" placeholder="Payment Reference ID">
    </mat-form-field>
    <div>
      <mat-form-field appearance="fill" class="mt-3">
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" placeholder="Amount">
      </mat-form-field>
      SGD
    </div>
    <span>After submission new email will be triggered to member with invoice details</span>
    <button class="btn btn-success mt-2" mat-raised-button
    [disabled]="valid === 'false' || form.invalid" type="submit">
    Received and Submit</button>
    <span class="error" *ngIf="error != null">{{ error }}</span>
  </form>
</div>

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { delay } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-approve-payment",
  templateUrl: "./approve-payment.component.html",
  styleUrls: ["./approve-payment.component.scss"],
})
export class ApprovePaymentComponent implements OnInit {
  form: FormGroup = this.fb.group({
    membershipID: new FormControl("", Validators.required),
    membershipType: new FormControl(
      { value: null, disabled: false },
      Validators.required
    ),
    amount: new FormControl("", [Validators.required]),
    paymentReferenceId: new FormControl("", Validators.required),
    purpose: new FormControl("", Validators.required),
    name: new FormControl({ value: null, disabled: false }, Validators.required),
  });

  valid: string;
  error: string;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  // openSnackBar() {
  //   this._snackBar.open("Payment Accepted for", this.form.value.membershipID, {
  //     duration: 2000,
  //   });
  // }

  validateId(): void {
    const id = this.form.value.membershipID;
    this.http.get(`${environment.api}memberNameIfExists/${id}`).subscribe(
      (res: any) => {
        if (res) {
          const data = {
            ...this.form.value,
            name: res.name,
            membershipType: res.membershipType,
          };
          this.form.patchValue(data);
          this.valid = 'true';
        } 
        else {
          const data = {
            ...this.form.value,
            name: res.name,
            membershipType: res.membershipType,
          };
          this.form.hasError("500");
        }
      },
      (err: any) => {
        this.valid = "false";
      }
    );
  }

  onSubmit(): void {
    if (this.valid === "true") {
      this.http
        .post(`${environment.api}admin/makePayment`, this.form.value)
        .subscribe(
          (res: any) => {
            this.router.navigate(["/admin", "payments"]);
          },
          (error: any) => {
            if (error.status === 200) {
              this.router.navigate(["/admin", "payments"]);
              this._snackBar.open("Payment Accepted for", this.form.value.membershipID, {
                duration: 2000,
              });
            } else {
              this.error = error.error;
              this._snackBar.open(this.error +' for', this.form.value.membershipID, {
                duration: 2000,
              });
            }
          }
        );
    }
  }

  ngOnInit(): void {}
}

import { ChangePasswordComponent } from './change-password/change-password.component';
import { PaymentsGuard } from './payments.guard';
import { RoleChangeComponent } from './role-change/role-change.component';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { RenewPaymentComponent } from './renew-payment/renew-payment.component';
import { MemberDonateComponent } from './member-donate/member-donate.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { MemberMessagesComponent } from './member-messages/member-messages.component';
import { AppSingleFormComponent } from './app-single-form/app-single-form.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { DonateComponent } from './donate/donate.component';
import { ProfileComponent } from './profile/profile.component';
import { MembersComponent } from './members/members.component';
import { EventsComponent } from './events/events.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, CanActivate, ExtraOptions } from '@angular/router';
import { AdminInboxComponent } from './admin-inbox/admin-inbox.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { PaymentsComponent } from './payments/payments.component';
import { AdminCenterComponent } from './admin-center/admin-center.component';
import { MemberLayoutComponent } from './member-layout/member-layout.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ApprovePaymentComponent } from './approve-payment/approve-payment.component';
import { ThankyouPaymentComponent } from './thankyou-payment/thankyou-payment.component';





const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  { path: '', component: LoginComponent },
  // { path: 'signup', component: AppSingleFormComponent },
  { path: 'signup', component: MakePaymentComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'log-out', component: LogoutComponent },
  { path: 'error-page', component: ErrorPageComponent },

  {
    path: 'member', component: MemberLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'messages', component: MemberMessagesComponent },
      // { path: 'make-payment', component: MakePaymentComponent },
      { path: 'donate', component: MemberDonateComponent },
      { path: 'renew-payment', component: RenewPaymentComponent },
      { path: 'member-profile', component: MemberProfileComponent },
      { path: 'edit-profile', component: EditProfileComponent },
      { path: 'events', component: EventsComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'thankyou-payment', component: ThankyouPaymentComponent },
    ]
  },


  {
    path: 'admin', component: AdminLayoutComponent,
    children: [
      { path: '', component: AdminDashboardComponent },
      { path: 'members', component: MembersComponent },
      { path: 'members/:all', component: MembersComponent },
      { path: 'events', component: EventsComponent },
      { path: 'messages', component: AdminInboxComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'payments', component: PaymentsComponent, canActivate: [PaymentsGuard] },
      { path: 'profile', component: ProfileComponent },
      { path: 'profile/:memberShipId', component: ProfileComponent },
      { path: 'donate', component: DonateComponent },
      { path: 'admin-center', component: AdminCenterComponent },
      { path: 'edit-profile', component: EditProfileComponent },
      { path: 'edit-profile/:memberShipId', component: EditProfileComponent },
      { path: 'role-change', component: RoleChangeComponent },
      { path: 'renew-payment', component: RenewPaymentComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'thankyou-payment', component: ThankyouPaymentComponent },
      { path: 'approve-payment', component: ApprovePaymentComponent, canActivate: [PaymentsGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  LoginComponent,
  ForgotPasswordComponent,
  DashboardComponent,
  AdminInboxComponent,
  SideNavBarComponent,
  AdminDashboardComponent,
  MembersComponent,
  EventsComponent,
  ReportsComponent,
  PaymentsComponent,
  ProfileComponent,
  DonateComponent,
  AdminCenterComponent,
  MemberMessagesComponent,
  MakePaymentComponent,
  MemberDonateComponent,
  RenewPaymentComponent,
  ThankYouComponent,
  LogoutComponent,
  MemberProfileComponent,
  EditProfileComponent,
  RoleChangeComponent,
  ChangePasswordComponent,
  ErrorPageComponent

]

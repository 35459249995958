
  <div class="row main-div">
    <div class="card col-md-3 col-lg-3 col-sm-12 mb-3">
      <div class="">
        <div class="text-center d-inline">
          <img
            class="card-img mt-3"
            src="../../assets/user.png"
            alt="Card image"
            style="width: 40%"
          />
        </div>
        <div class="card-body text-center px-0">
          <h4 class="card-title">{{ userName }}</h4>
          <p>{{ role }}</p>
          <h5 class="py-1 text-success">{{ userStatus }}</h5>
          <p style="margin-top: 0rem">Update profile Photo</p>
          <hr />
          <div class="text-left card-details px-5">
            <i class="fa ml-1 fa-star" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              Member Since <br />
              <span class="date">{{ createdOn | date: "dd-MMM-yyyy" }}</span>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-pencil" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/member-profile">View Profile</a>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-usd" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/dashboard">Payment History</a>
            </p>
          </div>
          <div class="card-details px-5">
            <i class="fa ml-1 fa-arrow-up" aria-hidden="true"></i>
            <p style="margin-left: 1rem">
              <a routerLink="/member/renew-payment">Upgrade</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-lg-1 col-md-1"></div> -->

    <div class="col-md-9 mx-auto">
      <h1>Notifications</h1>
      <div
        class="d-flex message mb-3 p-3 w-75"
        [class.read]="message.status === 'read'"
        (click)="message.status !== 'read' ? read(message.id) : ''"
        *ngFor="let message of messages"
      >
        <div>
          <img src="../../assets/avatar.png" width="50" height="50" />
        </div>
        <div class="cursor-pointer ml-3">
          {{ message.message }}
        </div>
      </div>
    </div>
  </div>

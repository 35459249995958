import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TestService } from '../test.service';
import { DonateService } from '../services/donate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-member-donate',
  templateUrl: './member-donate.component.html',
  styleUrls: ['./member-donate.component.scss'],
})
export class MemberDonateComponent implements OnInit {
  cause = [
    { name: 'Support Telugu Language Enrichment ',
      value: 'Support Telugu Language Enrichment'},
    { name: 'Social Services', value: 'Social Services'},
    { name: 'Healthy Lifestyle', value: 'Healthy Lifestyle'},
    { name: 'Cultural Cohesive', value: 'Cultural Cohesive'},
    { name: 'Sports', value: 'Sports'},
    { name: 'Devotional Support', value: 'Devotional Support'}
  ];

  selectedValue: string;
  donation = [
    { name: '20', value: 20 },
    { name: '50', value: 50 },
    { name: '100', value: 100 },
    { name: '200', value: 200 },
    { name: '500', value: 500 },
    { name: 'Other Amount', value: ''},
  ];

  anonymous = [
    {name: 'yes', value: 'Yes'},
    {name: 'no', value: 'No'}
  ];

  donateFor = [
    { name: '5', value: '5 KM' },
    { name: '10', value: '10 KM' },
    { name: '15', value: '15 KM' },
    { name: '20', value: '20 KM' }
  ];

  donateForValue: string;
  selectedAmount = 20;
  radioSelected = 20;
  isSelected = this.anonymous[1].value;
  membershipID: any;
  transactionRef: string="";

  userName:string;
  role: string;
  createdDate: string;
  userStatus: string;

  constructor( 
    private snackBar: MatSnackBar,
    private donateService: DonateService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router) {}

    ngOnInit(): void {
      this.userName = localStorage.getItem("name");
      this.role = localStorage.getItem("role");
      this.createdDate = localStorage.getItem("createdon");
      this.membershipID = localStorage.getItem('memberid');

      this.adminService.getMemberProfile(this.membershipID).subscribe((result) => {
        this.userStatus = result['memberStatus'];
      })
    }

    onSubmit(){

      if(this.transactionRef!=="" || this.transactionRef===null){
  
      const data = {
        membershipID: this.membershipID,
        amount: this.selectedAmount,
        paymentReferenceId: this.transactionRef
      }
      this.donateService.postDonationData(data).subscribe((postData) =>{
        console.log(postData);
        this.router.navigate(['/member/thankyou-payment']);
        });
      }
  
      else{
        { this.snackBar.open("Please enter valid Transaction ID", 
          this.transactionRef, {
            duration: 3000,
          });
        }
      }
    }

  onItemChange(item) {
    this.radioSelected = item.value;
    this.selectedAmount = item.value;
  }

  onChange(item) {
    this.isSelected = item.value;
  }

  selected(value: any) {
    if (value) {
      return this.cause.find(cause => cause.name = value)['value']
    }
   // console.log(value);
    return '';
  }
  donateForFn(value: any) {
    if (value) {
      // console.log(value);
      return this.donateFor.find(donateFor => donateFor.name = value)['value']
    }

    return '';
  }
}
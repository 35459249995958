export enum Endpoints {

  login = "authentication",
  forgotPwd ="forgotPwd",
  changePwd ="changePwd",
  addMember ="members",
  editMemberprofile ="member/updateMember",
  getMemberPayHist ="member/payments/",
  verifyMembershipId ="memberExists",
  verifyEmailId ="verifyEmail",
  membersInCards = "admin/getPendingMembersForApprove",
  approveMembership = "admin/approve",
  getActiveMembers = "admin/getActiveMembers",
  getFunds = "admin/getFunds",
  getFundsForToday = "admin/getFundsDaily",
  getProfile ="admin/getProfile",
  getAllFunds = "admin/getTotalAndPendingFunds",
  getAllMembers = "admin/getAllMembers",
  inactivateMembership = "admin/inactive",
  getAllGatheredFundsWithPaymentInfo ="admin/paymentInformation",
  getNotifications = "notifications",
  events="events",
  donate="donate",
  renewUpgradePayment="renewUpgrade",
  markAllAsRead = "markAllNotifications",
  markAsRead = "markNotifications"
}

import { AdminInboxService } from './../services/admin-inbox.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TestService } from './../test.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-admin-inbox',
  templateUrl: './admin-inbox.component.html',
  styleUrls: ['./admin-inbox.component.scss']
})
export class AdminInboxComponent implements OnInit {

  constructor(private test: TestService,
    // private activatedRoute: ActivatedRoute,
    // private router: Router,
    private adminService: AdminService,
    private alertService: AdminInboxService) { }

  testdata: any;
  messages: any;
  role: string;
  alertCount: number;
  membershipId: any;

  read(id: any) {
    this.adminService.markAsRead(id).subscribe(
      (res: any) => {
        this.ngOnInit();
      }, (err: any) => {
      }
    );
  }

  markAllAsRead() {
    this.adminService.markAllAsRead(this.membershipId).subscribe(
      (res: any) => {
        this.ngOnInit();
      }
    );
  }

  ngOnInit(): void {
    this.role = localStorage.getItem("role");
    this.membershipId = localStorage.getItem("memberid");
    this.adminService.getAllNotifications(this.membershipId).subscribe(result => {
      this.messages = result;
      this.alertService.alertCount = this.messages.length;
    })
  }
}



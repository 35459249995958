import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { NgbTab, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { Member } from "../models/member";
import { MemberServiceService } from "../services/member-service.service";
import { Router } from "@angular/router";
import { ThemePalette } from "@angular/material/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import * as moment from "moment";

class memberType {
  name: string;
  val: string;
}
@Component({
  selector: "app-single-form",
  templateUrl: "./app-single-form.component.html",
  styleUrls: ["./app-single-form.component.scss"],
})
export class AppSingleFormComponent implements OnInit {
  @ViewChild("tabset1") tabset1: NgbTabset;
  color: ThemePalette = "warn";
  isChildForm = false;
  isSwitchOne = false;
  isSwitchTwo = false;
  membershipOne: string;
  membershipTwo: string;
  membershipID: string;
  membershipIDType = "";
  membershipName1 = "";
  membershipName2 = "";
  loading: boolean;

  id1ValidateErrorMessage: string;
  id2ValidateErrorMessage: string;
  emailIdValidation: string;

  consentAgreement: boolean = false;
  termsConditions: boolean = false;

  membershipForm: FormGroup = this.formBuilder.group({
    firstName: ["", Validators.required],
    preferredName: [],
    dateOfBirth: [null, Validators.required],
    fin: [
      "",
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
    ],
    mobileNo: [""],
    nationality: [null, Validators.required],
    industry: [null],
    nativePlace: [null],
    emergencyContactInIndia: [""],
    surName: ["", Validators.required],
    gotram: ["", Validators.required],
    gender: [null, Validators.required],
    sgAddress: ["", Validators.required],
    email: ["", Validators.required],
    profession: ["", Validators.required],
    indiaAddress: [],
    relativeStayInSG: [],
    bloodGroup: [null, Validators.required],
    children: this.formBuilder.array([]),
  });
  membershipSpouseForm: FormGroup = this.formBuilder.group({
    firstNameSpouse: ["", Validators.required],
    preferredNameSpouse: [],
    finSpouse: ["", Validators.required],
    emailSpouse: [""],
    professionSpouse: ["", Validators.required],
    indiaAddressSpouse: [],
    bloodGroupSpouse: [null, Validators.required],
    surnameSpouse: ["", Validators.required],
    dateOfBirthSpouse: [null, Validators.required],
    mobileNoSpouse: [],
    nationalitySpouse: [null, Validators.required],
    industrySpouse: [null],
    nativePlaceSpouse: [],
  });
  generalForm: FormGroup = this.formBuilder.group({
    applicant: this.formBuilder.array([]),
    spouse: this.formBuilder.array([]),
    child: this.formBuilder.array([]),
    community: this.formBuilder.array([]),
  });
  memberTypes: memberType[] = [
    { name: "singleAnnual", val: "Single Annual" },
    { name: "singleLife", val: "Single Life" },
    { name: "familyAnnual", val: "Family Annual" },
    { name: "familyLife", val: "Family Life" },
  ];
  applicantChoices: Array<any> = [
    { name: "Career", value: "career" },
    { name: "Woman Empowerment", value: "WomanEmpowerment" },
    { name: "Social Service", value: "socialService" },
    { name: "Entrepreneur", value: "entrepreneur" },
  ];
  spouseChoices: Array<any> = [
    { name: "Arts", value: "arts" },
    { name: "Telugu Classes", value: "teluguClasses" },
  ];
  childrenChoices: Array<any> = [
    { name: "Art", value: "art" },
    { name: "Debates", value: "debates" },
    { name: "Science Projects", value: "scienceProjects" },
  ];
  communityChoices: Array<any> = [
    { name: "Sports", value: "sportsCom" },
    { name: "Social Service", value: "socialServicesCom" },
    { name: "Entrepreneur", value: "entrepreneurCom" },
    { name: "Arts", value: "artsCom" },
    { name: "Telugu Classes", value: "TelugueClassesCom" },
    { name: "Woman Empowerment", value: "WomanEmpowerment" },
  ];

  countryOptions: Array<any> = [
    { name: "Singaporean", value: "Singaporean" },
    { name: "Indian", value: "Indian" },
    { name: "British", value: "British" },
    { name: "American", value: "American" },
  ];

  industry: Array<any> = [
    { name: "IT", value: "IT" },
    { name: "Business", value: "Business" },
    { name: "Education", value: "Education" },
    { name: "Logistics", value: "Logistics" },
    { name: "Other", value: "Other" },
  ];

  genderValues: Array<any> = [
    { name: "Female", value: "F" },
    { name: "Male", value: "M" },
    { name: "Others", value: "NA" },
  ];

  blGroup: Array<any> = [
    { name: "A+", value: "A+" },
    { name: "A-", value: "A-" },
    { name: "B+", value: "B+" },
    { name: "B-", value: "B-" },
    { name: "AB+", value: "AB+" },
    { name: "AB-", value: "AB-" },
    { name: "O+", value: "O+" },
    { name: "O-", value: "O-" },
  ];

  nativePlaceArray: Array<any> = [
    { name: "Amaravathi", value: "Amaravathi" },
    { name: "Ananthapuram", value: "Ananthapuram" },
    { name: "Guntur", value: "Guntur" },
    { name: "Kadapa", value: "Kadapa" },
    { name: "Hyderabad", value: "Hyderabad" },
    { name: "Vijayawada", value: "Vijayawada" },
    { name: "Vizag", value: "Vizag" },
    { name: "Kurnool", value: "Kurnool" },
    { name: "Thirupathi", value: "Thirupathi" },
    { name: "Ongole", value: "Ongole" },
    { name: "Nellore", value: "Nellore" },
  ];

  selectedValue = "singleAnnual";

  submitted = false;
  dateOfBirth: any;

  onCheckboxChangeApplicant(e) {
    const applicantArray: FormArray = this.generalForm.get(
      "applicant"
    ) as FormArray;

    if (e.target.checked) {
      applicantArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      applicantArray.controls.forEach((item: FormControl) => {
        // tslint:disable-next-line: triple-equals
        if (item.value == e.target.value) {
          applicantArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeSpouse(e) {
    const spouseArray: FormArray = this.generalForm.get("spouse") as FormArray;

    if (e.target.checked) {
      spouseArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      spouseArray.controls.forEach((item: FormControl) => {
        // tslint:disable-next-line: triple-equals
        if (item.value == e.target.value) {
          spouseArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeChild(e) {
    const childArray: FormArray = this.generalForm.get("child") as FormArray;

    if (e.target.checked) {
      childArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      childArray.controls.forEach((item: FormControl) => {
        // tslint:disable-next-line: triple-equals
        if (item.value == e.target.value) {
          childArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeCom(e) {
    const comArray: FormArray = this.generalForm.get("community") as FormArray;

    if (e.target.checked) {
      comArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      comArray.controls.forEach((item: FormControl) => {
        // tslint:disable-next-line: triple-equals
        if (item.value == e.target.value) {
          comArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onChange1(e: MatSlideToggleChange) {
    this.isSwitchOne = e.checked;
  }
  onChange2(e: MatSlideToggleChange) {
    this.isSwitchTwo = e.checked;
  }
  valueChanged(event: any): void {
    if (event === "familyLife" || event === "familyAnnual") {
      Object.keys(this.membershipSpouseForm.controls).forEach(
        (control: string) => {
          this.membershipForm.addControl(
            control,
            this.membershipSpouseForm.get(control)
          );
        }
      );
    } else {
      Object.keys(this.membershipSpouseForm.controls).forEach(
        (control: string) => {
          this.membershipForm.removeControl(control);
        }
      );
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberServiceService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  get children(): FormArray {
    return this.membershipForm.get("children") as FormArray;
  }

  createChild() {
    this.isChildForm = true;
    const form: FormGroup = this.formBuilder.group({
      firstNameChild: ["", Validators.required],
      genderChild: [null],
      finChild: [null, Validators.required],
      studentClassChild: [],
      bloodGroupChild: [null, Validators.required],
      surnameChild: [null, Validators.required],
      dateOfBirthChild: [],
      schoolChild: [],
      nationalityChild: [null, Validators.required],
    });
    this.children.push(form);
  }

  removeChild(index: number) {
    if (this.children.length !== -1) {
      this.children.removeAt(index);
    } else {
      this.isChildForm = false;
    }
  }
  get f() {
    return this.membershipForm.controls;
  }

  validateId1(id: string) {
    console.log(id);
    if (this.membershipOne != null) {
      this.memberService
        .verifyIntroductoryId(this.membershipOne)
        .subscribe((result) => {
          console.log(result);
          if (!result || this.membershipOne === this.membershipTwo) {
            this.id1ValidateErrorMessage =
              "Please Provide Valid & Different Membership IDs";
          } else {
            this.id1ValidateErrorMessage = null;
          }
        });
    }
  }

  validateId2(id: string) {
    if (this.membershipTwo != null) {
      this.memberService
        .verifyIntroductoryId(this.membershipTwo)
        .subscribe((result) => {
          console.log(result);
          if (!result || this.membershipTwo === this.membershipOne) {
            this.id2ValidateErrorMessage =
              "Please Provide Valid & Different Membership IDs";
          } else {
            this.id2ValidateErrorMessage = null;
          }
        });
    }
  }

  validateEmail(email: string) {
    if (this.membershipForm.get("email").value != null) {
      this.memberService
        .verifyEmail(this.membershipForm.get("email").value)
        .subscribe((result) => {
          if (result) {
            this.emailIdValidation = "This Email Id Already Exists.";
          } else {
            this.emailIdValidation = null;
          }
        });
    }
  }

  onSubmit(type) {
    this.submitted = true;
    console.log(this.membershipForm.value);
    Object.keys(this.membershipForm.controls).forEach((key: string) => {
      if (this.membershipForm.get(key).errors) {
        console.log(this.membershipForm.get(key).errors);
        console.log(key);
      }
    });
    // stop here if form is invalid
    if (this.membershipForm.invalid) {
      return;
    }
    this.tabset1.select(type);
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.membershipForm.value, null, 4));
  }

  onReset() {
    this.submitted = false;
    this.membershipForm.reset();
  }
  onSave() {
    console.log(this.loading);
    if (
      this.id1ValidateErrorMessage != null &&
      this.id2ValidateErrorMessage != null &&
      this.emailIdValidation != null
    ) {
      this.loading = false;
      return;
    } else {
      const data = {
        membershipOne: this.membershipOne,
        membershipTwo: this.membershipTwo,
        membershipID: this.membershipID,
        firstName: this.membershipForm.get("firstName").value,
        preferredName: this.membershipForm.get("preferredName").value,
        dateOfBirth: this.membershipForm.get("dateOfBirth").value,
        surName: this.membershipForm.get("surName").value,
        gotram: this.membershipForm.get("gotram").value,
        membershipIDType: this.selectedValue,
        relativeStayInSG: this.membershipForm.get("relativeStayInSG").value,
        ...this.membershipForm.value,
        ...this.generalForm.value,
        isSwitchOne: this.isSwitchOne,
        isSwitchTwo: this.isSwitchTwo,
      };
      console.log(data.length);
      console.log(data);
      this.memberService.newMember(data).subscribe((result) => {
        console.log(result);
        if (result != null) {
          this.loading = false;
          this.router.navigate(["thank-you"]);
        } else {
          this.loading = false;
          this.router.navigate(["error-page"]);
        }
      });
    }
  }
}


  <nav class="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-light"
  style="background-color: #8f1313;">
    <a class="navbar-brand cursor-pointer" href="#">
      <img src="./assets/kcas_logo.png"
      width="75px" height="75px"/>
      <b class="mx-3 mt-2">KCAS</b></a>
    <button class="navbar-toggler" type="button"
    data-toggle="collapse" data-target="#navbarColor01"
    aria-controls="navbarColor01" aria-expanded="false"
    aria-label="Toggle navigation" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor01" [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/member/member-profile"
          >Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
          routerLink="/member/events"
          >Events</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/member/donate">Donate</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Resources</a>
        </li>
      </ul>
      <ul class="navbar-nav my-2 ">
        <li class="nav-item pt-1" style="cursor: pointer;">
          <a><mat-icon class="mr-2 mt-2"
            routerLink="/member/messages"
          [matBadge]="messagesCount"
          matBadgeColor="warn">notifications</mat-icon>
          </a>
        </li>

        <!-- <li class="nav-item pt-1">
          <a class="nav-link">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
          </a>
        </li> -->

        <li class="nav-item pt-1">
          <a class="nav-link">Help</a>
        </li>
        <li class="nav-item" >
          <a class="nav-link" routerLink="/member/member-profile">
            <img src="../../assets/user.png"
            style="height: 2rem; width: 2rem; border-radius: 100%;">
            </a>
        </li>
        <li class="nav-item pt-1">
          <a routerLink="/log-out"
          class="nav-link ">
            <mat-icon class="mat-icon">login</mat-icon></a>
        </li>
      </ul>
    </div>
  </nav>



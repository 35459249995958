import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-role-change',
  templateUrl: './role-change.component.html',
  styleUrls: ['./role-change.component.scss']
})
export class RoleChangeComponent implements OnInit {

  myControl = new FormControl();
  options: string[] = ['President', 'Treasurerer', 'Secretary'];
  filteredOptions: Observable<string[]>;
  activemembers: any;

  constructor(private adminService: AdminService,){}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.adminService.getActiveMembers().subscribe((data) => {
      this.activemembers = data;
      console.log(this.activemembers)
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}

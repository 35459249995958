import { Component, OnInit } from '@angular/core';

import { LoginService } from './../services/login-service';
import { MemberServiceService } from '../services/member-service.service';
import { Observable } from 'rxjs';
import { Member } from '../models/member';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  role: string;

  constructor(private loginService: LoginService,
    private memberService : MemberServiceService) { }
  userName:string;
  membershipId: string;
  email: string;
  createdOn: string;
  userStatus: string;
  paymentHistoryDetails: Member[];
 
  
  ngOnInit(): void {
    this.userName = localStorage.getItem("name");
    this.role = localStorage.getItem("role");
    this.membershipId = localStorage.getItem("memberid");
    this.email = localStorage.getItem("email");
    this.createdOn =  localStorage.getItem("createdon");
     if ( this.createdOn != null){
        this.userStatus ="ACTIVE";
     }
    console.log(this.userName);
    this.getMemberPaymentHist();

    
  }
  private getMemberPaymentHist(): void {
      this.memberService.getMembers(this.membershipId).subscribe (result => {
        console.log(result);
         this.paymentHistoryDetails = result;
      });
   }

  

}

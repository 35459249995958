
import { ScrollingVisibility } from "@angular/cdk/overlay";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-side-nav-bar",
  templateUrl: "./side-nav-bar.component.html",
  styleUrls: ["./side-nav-bar.component.scss"],
})
export class SideNavBarComponent implements OnInit {

  role: string;

  constructor() {}

  ngOnInit(): void {
    this.role = localStorage.getItem("role");
  }
}
